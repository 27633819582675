import React from "react"

import { Button, Checkbox } from "carbon-components-react"
import { ArrowUp32, ArrowDown32, TrashCan32 } from "@carbon/icons-react"

import TextInput from "../form_items/TextInput"
import Dropdown from "../form_items/Dropdown"

import moveObjectInArray from "../../utils/moveObjectInArray"
import { answerTypeItems, ratingTypeItems } from "../../constants/questionItems"

const QuestionsForm = ({ questions, onQuestionsChange }) => {
  const handleMoveQuestion = (from, to) => {
    onQuestionsChange(moveObjectInArray(questions, from, to))
  }

  const handleChangeQuestion = (id, attribute, value) => {
    const newQuestions = questions.map(question => question.id === id ? { ...question, [attribute]: { value, errors: [] } } : question)
    onQuestionsChange(newQuestions)
  }

  const handleRemoveQuestion = (id) => {
    onQuestionsChange(questions.filter(question => question.id !== id))
  }

  return questions.map((question, index) => (
    <div key={question.id} className="bx--tile fi--p--24 fi--my--24 fi--ml--32">
      <div className="fi--d-flex fi--justify-content--space-between">
        <p className="bx--type-productive-heading-02">Question</p>
        <div>
          <Button
            hasIconOnly
            kind="tertiary"
            size="small"
            renderIcon={ArrowUp32}
            tooltipAlignment="center"
            tooltipPosition="bottom"
            iconDescription="Move up"
            className="fi--mr--12"
            disabled={index === 0}
            onClick={() => handleMoveQuestion(index, index - 1)}
          />
          <Button
            hasIconOnly
            kind="tertiary"
            size="small"
            renderIcon={ArrowDown32}
            tooltipAlignment="center"
            tooltipPosition="bottom"
            iconDescription="Move down"
            className="fi--mr--12"
            disabled={index === questions.length - 1}
            onClick={() => handleMoveQuestion(index, index + 1)}
          />
          <Button
            hasIconOnly
            kind="tertiary"
            size="small"
            renderIcon={TrashCan32}
            tooltipAlignment="center"
            tooltipPosition="bottom"
            iconDescription="Remove"
            onClick={() => handleRemoveQuestion(question.id)}
          />
        </div>
      </div>
      <TextInput
        className="fi--mt--24"
        name="label"
        label="Question text"
        value={question.label.value}
        errors={question.label.errors}
        onChange={(_attribute, value) => handleChangeQuestion(question.id, "label", value)}
      />
      <Dropdown
        className="fi--mt--32"
        id={`answer_format-${question.id}`}
        label="Choose an option"
        title="Answer format"
        value={question.answerType.value}
        items={answerTypeItems}
        withEmptyOption={false}
        onChange={({ selectedItem }) => handleChangeQuestion(question.id, "answerType", selectedItem.id)}
      />
      { ["yes_no", "yes_no_na"].includes(question.answerType.value) && (
        <Dropdown
          className="fi--mt--32"
          id={`rating_type-${question.id}`}
          label="Choose an option"
          title="Rating category"
          value={question.ratingType.value}
          items={ratingTypeItems}
          withEmptyOption={true}
          onChange={({ selectedItem }) => handleChangeQuestion(question.id, "ratingType", selectedItem.id)}
        />
      )}

      <Checkbox
        className="fi--mt--32"
        id={`question-optional-${question.id}`}
        name={`question-optional-${question.id}`}
        value={`question-optional-${question.id}`}
        checked={question.optional.value}
        onChange={(checked) => handleChangeQuestion(question.id, "optional", checked)}
        labelText="Optional"
      />
    </div>
  ))
}

export default QuestionsForm
