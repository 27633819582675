const buildFacilities = ({ dealerGroups, dealerships, regions }) => {
  let allFacilities = []

  dealerGroups.forEach(dg => {
    allFacilities.push({ id: `DealerGroup:${dg.id}`, name: dg.name, hierarchyId: `DealerGroup:${dg.id}`, checklist: dg.checklist, status: dg.status })
    dg.regions.forEach(r => {
      allFacilities.push({ id: `Region:${r.id}`, name: r.name, hierarchyId: `Region:${r.id}|DealerGroup:${dg.id}`, checklist: r.checklist, status: r.status })
      r.dealerships.forEach(d => {
        allFacilities.push({ id: `Dealership:${d.id}`, name: d.name, hierarchyId: `Dealership:${d.id}|Region:${r.id}|DealerGroup:${dg.id}`, totalLicenses: d.totalLicenses, availableLicenses: d.availableLicenses, checklist: d.checklist, status: d.status })
      })
    })
    dg.dealerships.forEach(d => {
      allFacilities.push({ id: `Dealership:${d.id}`, name: d.name, hierarchyId: `Dealership:${d.id}|DealerGroup:${dg.id}`, totalLicenses: d.totalLicenses, availableLicenses: d.availableLicenses, checklist: d.checklist, status: d.status })
    })
  })

  regions.forEach(r => {
    allFacilities.push({ id: `Region:${r.id}`, name: r.name, hierarchyId: `Region:${r.id}`, checklist: r.checklist, status: r.status })
    r.dealerships.forEach(d => {
      allFacilities.push({ id: `Dealership:${d.id}`, name: d.name, hierarchyId: `Dealership:${d.id}|Region:${r.id}`, totalLicenses: d.totalLicenses, availableLicenses: d.availableLicenses, checklist: d.checklist, status: d.status })
    })
  })

  dealerships.forEach(d => {
    allFacilities.push({ id: `Dealership:${d.id}`, name: d.name, hierarchyId: `Dealership:${d.id}`, totalLicenses: d.totalLicenses, availableLicenses: d.availableLicenses, checklist: d.checklist, status: d.status })
  })

  return allFacilities
}
export default buildFacilities
