import React, { useState, useEffect } from "react"
import {
  Add16,
} from '@carbon/icons-react'

import {
  Grid,
  Column,
  Row,
} from 'carbon-components-react';

import SearchBar from "../form_items/SearchBar"
import PaginationNav from "../form_items/PaginationNav"

import NoChecklistTemplateFound from "../../../assets/images/icons/search-checklists.svg"
import AddChecklistTemplateImage from "../../../assets/images/icons/reports.svg";
import ChecklistTemplateDetail from "./Detail"

import { getRequest } from "../../utils/api";
import { gaEvent } from "../../utils/ga";

const defaultState = {
  searching: false,
  checklistTemplates: gon.checklistTemplates,
  checklistTemplateId: gon.checklistTemplateId,
  searchTerm: gon.searchTerm || "",
  currentPage: gon.currentPage,
  totalPages: gon.totalPages,
  totalCount: gon.totalCount,
}

const ChecklistTemplatesList = () => {
  const [state, setState] = useState(defaultState)

  const {
    searching,
    checklistTemplates,
    checklistTemplateId,
    searchTerm,
    currentPage,
    totalPages,
    totalCount,
  } = state

  const currentChecklistTemplate = checklistTemplates.find(template => template.id == checklistTemplateId)

  const handleSearchTermChange = (term) => {
    setState({ ...state,
      searching: term !== "",
      searchTerm: term,
      currentPage: 1,
    })
  }

  const handlePageChange = (page) => {
    setState({ ...state, currentPage: page })
  }

  useEffect(() => {
    updateLocation();
  }, [checklistTemplateId])

  useEffect(() => {
    updateLocation();
    fetchSearchResults();
  }, [searchTerm, currentPage])

  const updateLocation = () => {
    const params = {
      page: currentPage,
      term: searchTerm,
      id: checklistTemplateId,
    }

    let searchParams = []
    Object.keys(params).forEach(key => {
      const value = params[key]

      if (value && String(value).length > 0) {
        searchParams = [...searchParams, `${key}=${value}`]
      }
    })

    window.history.pushState("", "", `?${searchParams.join("&")}`);
  }

  const fetchSearchResults =  async () => {
    try {
      const { response, data } = await getRequest(window.location.href)
      const { checklistTemplates = [], checklistTemplateId, totalPages, totalCount, currentPage } = data

      if (response.ok) {
        setState({ ...state, checklistTemplates, checklistTemplateId, totalPages, totalCount, currentPage })
        window.scrollTo(0, 0)
      }
    } catch(e) {
      console.error("Search failed", e)
    }
  }

  const handleChecklistTemplateChange = (value) => {
    gaEvent("Checklist templates", "Select Cheklist template click", `User: ${value}`)
    setState({ ...state, checklistTemplateId: value } )
  }

  const renderNoChecklistTemplates = () => (
    <div className="fi--centered-content">
      <img src={AddChecklistTemplateImage} width="160" className="fi--mt--96"/>
      <p className="bx--type-productive-heading-06 fi--mt--64">Create a checklist to get started</p>
      <p className="bx--type-body-long-02 fi--mt--24">You can assign a custom checklist to a dealership to use instead of the default checklist.</p>
    </div>
  )

  const renderNotFound = () => (
    <React.Fragment>
      <div className="fi--centered-content">
        <img src={NoChecklistTemplateFound} width="160" className="fi--mt--96"/>
        <p className="bx--type-productive-heading-06 fi--mt--48">No checklists found</p>
        <p className="bx--type-body-long-02 fi--mt--24">Try adjusting your search to find what you’re looking for.</p>
      </div>
    </React.Fragment>
  )

  const renderChecklistTemplates = () => {
    return(
      <Grid fullWidth className="fi--px--0 fi--mt--24">
        <Row className="fi--px--16">
          <Column lg={5} className="fi--p--0">
            <div className="bx--tile fi--pt--16 fi--px--0 fi--pb--0">
              <h6 className="fi--mb--16 fi--ml--16">{totalCount} {totalCount != 1 ? "checklists" : "checklist"}</h6>
              <ul className="bx--side-nav__items fi--pt--0">
                {
                  checklistTemplates.map(template => (
                    <li  key={template.id} className={`bx--side-nav__item${checklistTemplateId == template.id ? " bx--side-nav__link--current" : ""} fi--border--top fi--height--auto fi--width--100`}>
                      <a className={`bx--side-nav__link fi--py--16${checklistTemplateId == template.id ? " bx--side-nav__link--current" : ""}`} onClick={() => handleChecklistTemplateChange(template.id)}>
                        <span className="bx--side-nav__link-text fi--font-weight--normal">{template.name}</span>
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="bx--tile fi--border--top fi--p--0" style={{ minHeight: "0px" }}>
              {(totalCount > 0 ) &&
                <PaginationNav
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onChange={handlePageChange}
                />
              }
            </div>
          </Column>
          <Column lg={11} className="fi--pl--32 fi--pr--0">
            <ChecklistTemplateDetail template={currentChecklistTemplate}/>
          </Column>
        </Row>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <div className="fi--mt--24 fi--d-flex">
        <a href="/checklist_templates/new" className="bx--btn bx--btn--primary" data-gaevent="Add custom checklist click" data-gacategory="Custom checklist">
          <span>Create checklist</span>
          <Add16 className="bx--btn__icon"/>
        </a>

        <SearchBar
          initialValue={gon.searchTerm}
          onChange={handleSearchTermChange}
          delay={500}
        />
      </div>

      { checklistTemplates.length == 0 ? (searching ? renderNotFound() : renderNoChecklistTemplates()) : renderChecklistTemplates() }

    </React.Fragment>
  )
}

export default ChecklistTemplatesList
