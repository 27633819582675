import React, { useState, useEffect, useRef } from "react"
import { Row, Grid, Tile, Column, Dropdown } from "carbon-components-react"

import PieChart from "./PieChart"
import VideosOverTime from "./VideosOverTime"
import { getRequest } from "../../utils/api"

import sortBy from "../../utils/sortBy"

const { dashboard } = gon

const timeItems = [
  { id: 'last_week', label: 'Last week' },
  { id: 'last_month', label: 'Last month' },
]

const defaultState = {
  facilities: dashboard.facilities,
  users: dashboard.users,
  facilityId: dashboard.facilityId || null,
  userId: dashboard.userId || null,
  time: dashboard.time || 'last_week',
  data: dashboard.data || [],
}

const DashboardShow = () => {
  const [state, setState] = useState(defaultState)
  const isFirstRun = useRef(true);

  const {
    facilities,
    users,
    facilityId,
    userId,
    time,
    data,
  } = state

  const videosCreated = data.reduce((total, day) => day.videosCreated + total, 0)
  const videosViewed = data.reduce((total, day) => day.videosViewed + total, 0)
  const dealsLogged = data.reduce((total, day) => day.dealsLogged + total, 0)
  const dealsReviewed = data.reduce((total, day) => day.dealsReviewed + total, 0)

  const facilityItems = [{ id: null, label: `All ${facilities.length}`}, ...sortBy(facilities, "label")]
  const userItems = [{ id: null, label: `All ${users.length}`}, ...sortBy(users, "label")]

  const selectedFacility = facilityItems.find(f => f.id === facilityId)
  const selectedUser = userItems.find(u => u.id === userId)
  const selectedTime = timeItems.find(t => t.id === time)

  const handleFacilityChange = ({ selectedItem }) => {
    setState({...state, facilityId: selectedItem.id, userId: null })
  }

  const handleUserChange = ({ selectedItem }) => {
    setState({...state, userId: selectedItem.id })
  }

  const handleTimeChange = ({ selectedItem }) => {
    setState({...state, time: selectedItem.id })
  }

  const updateUrl = () => {
    const params = {
      facility_id: facilityId,
      user_id: userId,
      time,
    }

    let searchParams = []
    Object.keys(params).forEach(key => {
      const value = params[key]

      if (value && String(value).length > 0) {
        searchParams = [...searchParams, `${key}=${value}`]
      }
    })

    window.history.pushState("", "", `?${searchParams.join("&")}`);
  }

  const fetchDashboard = async () => {
    try {
      const { data, response } = await getRequest(window.location.href)

      if (response.ok) {
        const { dashboard: { users, facilities, data: dashboardData }  } = data
        setState({...state, users, facilities, data: dashboardData })
      }
    } catch(e) {
      alert("We had issues fetching the data for the dashboard")
      console.error(e)
    }
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    updateUrl()
    fetchDashboard()
  }, [facilityId, userId, time])

  return(
    <div className="fi--p--32">
      <h1 className="bx--type-productive-heading-05">Dashboard</h1>
      <Grid fullWidth className="fi--px--0 fi--mt--48">
        <Row>
          <Column>
            <Tile className="fi--p--24 fi--pt--16 fi--height--100">
              <PieChart
                data={[{value: Math.max(videosCreated, 1), color: "#c9e9cc"}]}
                title={videosCreated}
              />
              <p className="bx--type-productive-heading-01 fi--mt--8">Videos created</p>
              <p className="bx--type-label-01">with customer present</p>
            </Tile>
          </Column>
          <Column>
            <Tile className="fi--p--24 fi--pt--16 fi--height--100">
              <PieChart
                data={[{ value: Math.max(videosViewed, 1), color: "#347e3c" }]}
                title={videosViewed}
              />
              <p className="bx--type-productive-heading-01 fi--mt--8">Total videos viewed</p>
              <p className="bx--type-label-01">with customer present</p>
            </Tile>
          </Column>
          <Column>
            <Tile className="fi--p--24 fi--pt--16 fi--height--100">
              <PieChart
                data={[{value: Math.max(dealsLogged, 1), color: "#bfd7f2"}]}
                title={dealsLogged}
              />
              <p className="bx--type-productive-heading-01 fi--mt--8">Deals logged</p>
            </Tile>
          </Column>
          <Column>
            <Tile className="fi--p--24 fi--pt--16 fi--height--100">
              <PieChart
                data={[{ value: Math.max(dealsReviewed, 1), color: "#1e5695" }]}
                title={dealsReviewed}
              />
              <p className="bx--type-productive-heading-01 fi--mt--8">Total deals reviewed</p>
              <p className="bx--type-label-01">(checklist completed)</p>
            </Tile>
          </Column>
          <Column>
            <p className="bx--type-productive-heading-01">Filters</p>
            <div className="fi--dashboard-filter">
              <span className="fi--dashboard-filter__title">Facilities</span>
              <Dropdown
                id="facilities"
                light
                size="sm"
                className="fi--dashboard-filter__dropdown"
                label="Select facilities"
                items={facilityItems}
                selectedItem={selectedFacility}
                onChange={handleFacilityChange}/>
            </div>
            <div className="fi--dashboard-filter">
              <span className="fi--dashboard-filter__title">Users</span>
              <Dropdown
                id="users"
                light
                size="sm"
                className="fi--dashboard-filter__dropdown"
                label="Select users"
                items={userItems}
                selectedItem={selectedUser}
                onChange={handleUserChange}/>
            </div>
            <div className="fi--dashboard-filter">
              <span className="fi--dashboard-filter__title">Time</span>
              <Dropdown
                id="time"
                light
                size="sm"
                className="fi--dashboard-filter__dropdown"
                items={timeItems}
                label="Select time"
                selectedItem={selectedTime}
                onChange={handleTimeChange}/>
            </div>
          </Column>
        </Row>
        <Row>
          <Column className="fi--mt--32">
            <VideosOverTime data={data}/>
          </Column>
        </Row>
      </Grid>
    </div>
  )
}

export default DashboardShow
