import React from "react"
import PropTypes from "prop-types"

import RadioButtonGroup from "../../form_items/RadioButtonGroup"

import { durationItems } from "../../../constants/filterItems"

const DealTransactionsFiltersByDuration = ({name, selectedItem = "", onChange, disabled = false}) => {

  const handleClear = () => {
    onChange(name, "")
  }

  return (
    <React.Fragment>
      <div className="fi--d-flex fi--justify-content--space-between">
      <h5 className="fi--mt--24">Duration</h5>
        { selectedItem != "" && (<button className="bx--link fi--mt--24" onClick={handleClear}>Clear</button>) }
      </div>
      <RadioButtonGroup
        className="fi--mt--0 fi--mb--0"
        items={durationItems}
        label=""
        name="duration"
        selectedValue={selectedItem}
        onChange={onChange}
        disabled={disabled}
      />
    </React.Fragment>
  )
}

DealTransactionsFiltersByDuration.propTypes = {
  name: PropTypes.string,
  selectedItem: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default DealTransactionsFiltersByDuration
