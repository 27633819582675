import React, { useState, useRef, useEffect } from "react"
import {
  Row,
  Column,
  MultiSelect,
} from "carbon-components-react"

import NoReports from "../../../assets/images/icons/reports.svg"
import infoNotAccurate from "../../../assets/images/icons/warning.svg"

import DatePicker from "../form_items/DatePicker"

import { getRequest } from "../../utils/api"
import { gaEvent } from "../../utils/ga";

const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 }) //eslint-disable-line

let regionItems = gon.report.regionItems.map(({ id, name }) => ({ id: id, label: name }))
if (gon.report.noRegion) {regionItems.push({id: "no_region", label: "No region"})}
const selectedRegions = gon.selectedRegions ? regionItems.filter(r => gon.selectedRegions.includes(r.id.toString())) : []
const dealershipItems = gon.report.dealershipItems.map(({ id, name, regionId }) => ({ id, label: name, region: regionId && regionItems.map(r => r.id).includes(regionId) ? regionId : "no_region"}))

const defaultState = {
  data: gon.report || [],
  startDate: gon.startDate ? gon.startDate : "",
  endDate: gon.endDate ? gon.endDate : "",
  generating: false,
  accurateInfo: true,
  selectedRegions: selectedRegions,
  selectedDealerships: gon.selectedDealerships ? dealershipItems.filter(d => gon.selectedDealerships.includes(d.id.toString())) : [],
  dealershipItems: selectedRegions.length != 0 ? dealershipItems.filter(d => selectedRegions.map(r => r.id).includes(d.region)) : dealershipItems,
  emailSent: gon.emailSent,
}

const SalesSummary = () => {
  const [state, setState] = useState(defaultState)
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setState({ ...state, accurateInfo: false })
  }, [state.startDate, state.endDate, state.selectedRegions, state.selectedDealerships])

  const handleDateChange = (attribute, value) => {
    setState({ ...state, [attribute]: value })
  }

  const fetchReport = async () => {
    setState({ ...state, generating: true })
    try {
      const { data, response } = await getRequest(window.location.href)

      if (response.ok) {
        setState({ ...state, data: data.report, generating: false, accurateInfo: true, emailSent: data.emailSent })
      }
    } catch (e) {
      alert("We had issues fetching the data for the report")
      console.error(e)
    }
  }

  const handleGenerate = () => {
    gaEvent("Reports", "Generate Sales summary")
    const params = {
      start_date: state.startDate,
      end_date: state.endDate,
      region: state.selectedRegions.map(r => r.id).join(","),
      dealership: state.selectedDealerships.map(d => d.id).join(","),
    }

    let searchParams = []
    Object.keys(params).forEach(key => {
      const value = params[key]

      if (value && String(value).length > 0) {
        searchParams = [...searchParams, `${key}=${value}`]
      }
    })

    window.history.pushState("", "", `?${searchParams.join("&")}`);
    fetchReport()
  }

  const handleRegionsChange = ({selectedItems}) => {
    const newDealershipItems = selectedItems.length != 0 ? dealershipItems.filter(d => selectedItems.map(r => r.id).includes(d.region)) : dealershipItems
    setState({ ...state, selectedRegions: selectedItems, selectedDealerships: [], dealershipItems: newDealershipItems })
    document.querySelectorAll(".dealership-selector .bx--list-box__selection--multi").forEach(e => e.click());
  }

  const handleDealershipsChange = ({selectedItems}) => {
    setState({ ...state, selectedDealerships: selectedItems })
  }

  const renderNoReport = () => (
    <div className="fi--centered-content">
      <img src={NoReports} width="160" className="fi--mt--96" />
      <p className="bx--type-expressive-heading-05 fi--mt--64">No data available</p>
      <p className="bx--type-body-short-02 fi--mt--24">Enter a valid start and end date to generate a report.</p>
    </div>
  )

  const renderEmailSent = () => (
    <div className="fi--centered-content">
      <img src={NoReports} width="160" className="fi--mt--96" />
      <p className="bx--type-expressive-heading-05 fi--mt--64">To much data to be displayed</p>
      <p className="bx--type-body-short-02 fi--mt--24">An email will be sent with the report data.</p>
    </div>
  )

  const renderDealership = (dealership) => {
    return (
      <React.Fragment>
        <tr>
          <td> </td>
          <td className="fi--bg--green-15"> <p>{dealership.name}</p> </td>
          <td className="fi--bg--green-15"> Total </td>
          <td className="fi--bg--green-15"> {dealership.totalDeals} </td>
          <td className="fi--bg--green-15"> {dealership.totalVideos} </td>
          <td className="fi--bg--green-15"> {dealership.videoDuration ? new Date(Math.trunc(dealership.videoDuration / dealership.totalVideos)).toISOString().substr(11, 8) : "00:00:00"} </td>
          <td className="fi--bg--green-15"> {currency.format(dealership.frontGross)} </td>
          <td className="fi--bg--green-15"> {currency.format(dealership.backGross)} </td>
          <td className="fi--bg--green-15"> {currency.format(dealership.totalGross)} </td>
          <td className="fi--bg--green-15"> {currency.format(dealership.financeReserve)} </td>
          <td className="fi--bg--green-15"> {currency.format(dealership.vsc)} </td>
          <td className="fi--bg--green-15"> {currency.format(dealership.other)} </td>
        </tr>
        {
          dealership.managers.map(manager => (
            <tr key={manager.id}>
              <td> </td>
              <td> </td>
              <td> <p>{manager.name}</p> </td>
              <td> {manager.totalDeals} </td>
              <td> {manager.totalVideos} </td>
              <td> {manager.videoDuration ? new Date(Math.trunc(manager.videoDuration / manager.totalVideos)).toISOString().substr(11, 8) : "00:00:00"} </td>
              <td> {currency.format(manager.frontGross)} </td>
              <td> {currency.format(manager.backGross)} </td>
              <td> {currency.format(manager.totalGross)} </td>
              <td> {currency.format(manager.financeReserve)} </td>
              <td> {currency.format(manager.vsc)} </td>
              <td> {currency.format(manager.other)} </td>
            </tr>
          ))
        }
      </React.Fragment>
    )
  }

  const renderReport = () => {
    return (
      <div className="fi--mt--24">
        <table className="bx--data-table  bx--data-table--compact fi--table--report" id={"tableId"}>
          <thead>
            <tr>
              <th> <span className="bx--table-header-label">Region</span> </th>
              <th> <span className="bx--table-header-label">Dealership</span> </th>
              <th> <span className="bx--table-header-label">F&I Manager</span> </th>
              <th> <span className="bx--table-header-label">Unique deals logged</span> </th>
              <th> <span className="bx--table-header-label">Unique videos recorded</span> </th>
              <th> <span className="bx--table-header-label">Average video duration</span> </th>
              <th> <span className="bx--table-header-label">Front end gross</span> </th>
              <th> <span className="bx--table-header-label">Back end gross</span> </th>
              <th> <span className="bx--table-header-label">Total gross</span> </th>
              <th> <span className="bx--table-header-label">Finance reserve income</span> </th>
              <th> <span className="bx--table-header-label">VSC Sales</span> </th>
              <th> <span className="bx--table-header-label">Other product sales</span> </th>
            </tr>
          </thead>
          <tbody>
            {
              state.data.regions.map(region => (
                <React.Fragment key={region.id}>
                  <tr>
                    <td className="fi--bg--blue-15"> <p>{region.name}</p> </td>
                    <td className="fi--bg--blue-15"> <p>Grand total</p> </td>
                    <td className="fi--bg--blue-15"> </td>
                    <td className="fi--bg--blue-15"> {region.totalDeals} </td>
                    <td className="fi--bg--blue-15"> {region.totalVideos} </td>
                    <td className="fi--bg--blue-15"> {region.videoDuration ? new Date(Math.trunc(region.videoDuration / region.totalVideos)).toISOString().substr(11, 8) : "00:00:00"} </td>
                    <td className="fi--bg--blue-15"> {currency.format(region.frontGross)} </td>
                    <td className="fi--bg--blue-15"> {currency.format(region.backGross)} </td>
                    <td className="fi--bg--blue-15"> {currency.format(region.totalGross)} </td>
                    <td className="fi--bg--blue-15"> {currency.format(region.financeReserve)} </td>
                    <td className="fi--bg--blue-15"> {currency.format(region.vsc)} </td>
                    <td className="fi--bg--blue-15"> {currency.format(region.other)} </td>
                  </tr>
                  {
                    region.dealerships.map(dealership => (
                      <React.Fragment key={dealership.id}>
                        {renderDealership(dealership)}
                      </React.Fragment>
                    ))
                  }
                </React.Fragment>
              ))
            }
            {state.data.dealerships.length != 0 && state.data.regions.length != 0 &&
              <tr>
                <td className="fi--bg--blue-15"> <p>No region</p> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
                <td className="fi--bg--blue-15"> </td>
              </tr>
            }
            {
              state.data.dealerships.map(dealership => (
                <React.Fragment key={dealership.id}>
                  {renderDealership(dealership)}
                </React.Fragment>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="fi--p--24">
      <Row style={{ alignItems: 'flex-end' }}>
        <Column sm={4} md={3} lg={3}>
          <DatePicker
            id="startDate"
            name="startDate"
            light={false}
            className="fi--mt--16"
            label="Start date"
            value={state.startDate}
            onChange={handleDateChange}
          />
        </Column>
        <Column sm={4} md={3} lg={3}>
          <DatePicker
            id="endDate"
            name="endDate"
            light={false}
            className="fi--mt--16"
            label="End date"
            value={state.endDate}
            onChange={handleDateChange}
          />
        </Column>
        { !state.accurateInfo &&
          <img src={infoNotAccurate} className="fi--mb--8" width="25px" height="25px" title="The information may not be accurate"/>
        }
        <Column sm={4} md={3} lg={3}>
          <button disabled={state.generating} className="bx--btn bx--btn--primary fi--btn--text-center-small bx--btn--field" type="button" onClick={handleGenerate}>Generate report</button>
        </Column>
        <Column sm={4} md={3} lg={3}>
        <a
          disabled={state.generating}
          className="bx--btn bx--btn--secondary fi--btn--text-center-small bx--btn--field"
          type="button"
          href={`${window.location.href}${window.location.href.includes("?") ? "&" : "?"}format=pdf`}
          target="_blank"
          rel="noreferrer"
          data-gaevent="Download sales summary pdf"
          data-gacategory="Reports"
        >Print as PDF</a>
        </Column>
      </Row>
      <Row className="fi--mt--24">
      { regionItems.length > 1 &&
        <Column sm={4} md={3} lg={3}>
          <MultiSelect
            id="regions-filter"
            items={regionItems}
            label="Regions"
            onChange={handleRegionsChange}
            initialSelectedItems={state.selectedRegions}
          />
        </Column>
      }
      { state.dealershipItems.length > 1 &&
        <Column sm={4} md={3} lg={3}>
          <div className="dealership-selector">
            <MultiSelect
              id="dealership-filter"
              items={state.dealershipItems}
              label="Dealerships"
              onChange={handleDealershipsChange}
              initialSelectedItems={state.selectedDealerships}
              selectedItems={state.selectedDealerships}
            />
          </div>
        </Column>
      }
      </Row>
      {state.emailSent ? renderEmailSent() : (!state.data.noData ? renderReport() : renderNoReport())}
    </div>
  )
}

export default SalesSummary
