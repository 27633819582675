import React, { useState, useEffect, useRef } from "react"
import {
  InlineNotification,
} from 'carbon-components-react';

import UserAddedImage from "../../../assets/images/icons/user-added.svg";

import TextInput from "../form_items/TextInput"
import CheckboxList from "../form_items/CheckboxList"
import PermissionsAccordion from "./PermissionsAccordion"
import Subscriptions from "./Subscriptions"
import Toggle from "../form_items/Toggle"

import { postRequest, putRequest } from "../../utils/api"
import buildFacilities from "../../utils/buildFacilities";

import { gaEvent } from "../../utils/ga";

const { user, totalRoles, roles } = gon

const defaultState = {
  submitError: null,
  submitSuccess: false,
  firstName: { value: user ? user.firstName : "", errors: [] },
  lastName: { value: user ? user.lastName : "", errors: [] },
  email: { value: user ? user.email : "", errors: [] },
  rolesSelected: { value: user ? roles.map(r => r.id.toString()) : [], errors: [] },
  subscriptions: { value: user ? user.subscriptions : [], errors: [] },
  recordingBetaTester: { value: user?.recordingBetaTester ?? false, errors: []},
  status: { value: user ? user.status : "active", errors: []},
  facilities: buildFacilities(gon)
}

const newAction = !user
const roleItems = totalRoles.map(({id, name}) => ({ id, label: name }))

const UsersNew = () => {
  const [state, setState] = useState(defaultState)
  const isFirstRun = useRef(true);

  const {
    submitError,
    submitSuccess,
    firstName,
    lastName,
    email,
    rolesSelected,
    subscriptions,
    recordingBetaTester,
    status,
    facilities,
  } = state

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setState({ ...state, submitError: null })
  }, [subscriptions])

  const handleUserChange = (attribute, value) => {
    setState({ ...state, [attribute]: { value, errors: [] } })
  }

  const handleSubscriptionsChange = (newSubscriptions, facilities) => {
    setState({ ...state, subscriptions: { value: newSubscriptions, errors: [] }, facilities })
  }

  const handleRoleChange = (_attribute, roles) => {
    setState({
      ...state,
      rolesSelected: { value: roles, errors: [] },
      facilities: defaultState.facilities,
      subscriptions: defaultState.subscriptions,
    })
  }

  const handleRecordingBetaTesterChange = () => {
    setState({
      ...state,
      recordingBetaTester: { value: !recordingBetaTester.value },
    })
  }

  const handleStatusChange = () => {
    setState({
      ...state,
      status: { value: status.value == "active" ? "inactive" : "active" },
    })
  }

  const handleSubmit = async () => {
    gaEvent("Users", newAction ? "Add button click" : "Update button click")
    try {
      const postOrPut = newAction ? postRequest : putRequest
      const { response, data } = await postOrPut(newAction ? '/users' : `/users/${user.id}`, {
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        user_roles_attributes: state.rolesSelected.value.map((roleId) => (
          {
            role_id: parseInt(roleId),
          }
        )),
        recording_beta_tester: recordingBetaTester.value,
        status: status.value,
        subscriptions_attributes: state.subscriptions.value.map(({id, facilityType, facilityId, _destroy}) => (
          {
            id,
            facility_type: facilityType,
            facility_id: facilityId,
            _destroy: _destroy,
          }
        )),
      })

      const { errors = {} } = data

      if (response.ok) {
        newAction ? setState({ ...state, submitSuccess: true }) : handleUpdate()
      } else {
        assignErrors(errors)
        window.scrollTo(0, 0)
      }
    } catch(_e) {
      setState({ ...state, submitError: `An error ocurred ${newAction ? "creating" : "updating"} the user. Please try again or contact support` })
    }
  }

  const handleCancel = () => {
    gaEvent("Users", "Cancel button click")
    window.location.href = "/users"
  }

  const handleUpdate = () => {
    window.location.href = `/users?id=${user.id}`
  }

  const assignErrors = (errors) => {
    setState({
      ...state,
      submitError: errors.base || errors["subscriptions.base"],
      firstName: { ...firstName, errors: errors.firstName || [] },
      lastName: { ...lastName, errors: errors.lastName || [] },
      email: { ...email, errors: errors.email || [] },
      rolesSelected: { ...rolesSelected, errors: errors.roles || [] },
    })
  }

  const renderUserCreated = () => (
    <div className="bx--tile fi--py--96 fi--centered-content">
      <img src={UserAddedImage} width="160"/>
      <p className="bx--type-expressive-heading-04 fi--mt--64">User successfully added</p>
      <div className="fi--mt--24">
        <a href="/users/new" className="bx--btn bx--btn--primary fi--btn--text-center fi--mr--12" data-gaevent="Add another click" data-gacategory="Users">Add another user</a>
        <a href="/users" className="bx--btn bx--btn--secondary fi--btn--text-center fi--ml--12" data-gaevent="Return click" data-gacategory="Users">Return to users</a>
      </div>
    </div>
  )

  const renderForm = () => (
    <React.Fragment>
      <div className="bx--tile fi--p--24">
        <h4>{newAction ? "Add user" : "Edit user"}</h4>
        { newAction &&
           <p className="bx--type-body-short-01 fi--mt--8">Once you add a user, F&Insight will send them an email invite to create a password.</p>
        }

        <TextInput className="fi--mt--32" name="firstName" label="First name" value={firstName.value} errors={firstName.errors} onChange={handleUserChange} />
        <TextInput className="fi--mt--24" name="lastName" label="Last name" value={lastName.value} errors={lastName.errors} onChange={handleUserChange} />
        <TextInput className="fi--mt--24" name="email" label="Email" value={email.value} errors={email.errors} onChange={handleUserChange} />

        <CheckboxList
          className="fi--mt--24 fi--mb--0"
          items={roleItems}
          label="Roles"
          name="roles"
          selectedItems={rolesSelected.value}
          errors={rolesSelected.errors}
          onChange={handleRoleChange}
        />

        <PermissionsAccordion className="fi--mt--16"/>

        <Subscriptions
          individualSubscriptions={rolesSelected.value.map(id => roleItems.find(role => role.id == id).label).includes("Individual")}
          onlyIndividual={rolesSelected.value.length == 1 && roleItems.find(role => role.id == rolesSelected.value[0]).label == "Individual"}
          selectedSubscriptions={subscriptions.value}
          onSubscriptionsChange={handleSubscriptionsChange}
          facilities={facilities}
        />

        <Toggle
          id="toggle-recording-beta-tester"
          label="Recording beta tester"
          onLabel="Yes"
          offLabel="No"
          onChange={handleRecordingBetaTesterChange}
          className="fi--mt--24"
          checked={recordingBetaTester.value}
        />

        { !newAction && (
          <Toggle
            id="toggle-status"
            label="Status"
            onLabel="Active"
            offLabel="Inactive"
            onChange={handleStatusChange}
            className="fi--mt--24"
            checked={status.value === "active"}
          />
        )}
      </div>

      <div className="bx--modal-footer">
        <button className="bx--btn bx--btn--secondary" onClick={handleCancel} type="button">Cancel</button>
        <button className="bx--btn bx--btn--primary" onClick={handleSubmit} type="button">{newAction ? "Add user" : "Update user"}</button>
      </div>
    </React.Fragment>
  )

  return (
    <div>
      { submitError &&
        <InlineNotification
          kind="error"
          iconDescription="close button"
          subtitle={<span>{submitError}</span>}
          title={`Error ${newAction ? "creating" : "updating"} user`}
        />
      }
      { submitSuccess ? renderUserCreated() : renderForm() }

    </div>
  )
}

export default UsersNew
