import React, { useState, useRef, useEffect } from "react"
import {
  InlineNotification,
} from 'carbon-components-react';

import { gaEvent } from "../../utils/ga";

import TextInput from "../form_items/TextInput"
import RadioButtonGroup from "../form_items/RadioButtonGroup"
import Dropdown from "../form_items/Dropdown"
import { postRequest } from "../../utils/api";

const {
  saleTypes,
  dealTypes,
  dealerships,
  dealTransaction,
  relatedDealTransactions,
} = gon

const saleTypeItems = saleTypes.map(({id, name}) => ({ id, label: name }))
const dealTypeItems = dealTypes.map(({id, name}) => ({ id, label: name }))
const dealershipItems = dealerships.map(({id, name}) => ({ id, label: name }))

const defaultDealership = dealerships.length == 1 ? dealerships[0].id : ""

const defaultState = {
  submitError: null,
  startSuccess: !!dealTransaction,
  searchSuccess: !!dealTransaction,
  dealId: dealTransaction ? dealTransaction.dealNumber : null,
  relatedDealTransactions: relatedDealTransactions || [],
  dealTransactionId: dealTransaction ? dealTransaction.id : null,
  customerFirstName: { value: dealTransaction ? dealTransaction.customerFirstName : "", errors: [] },
  customerLastName: { value: dealTransaction ? dealTransaction.customerLastName : "", errors: [] },
  stockNumber: { value: dealTransaction ? dealTransaction.stockNumber : "", errors: [] },
  saleType: { value: dealTransaction ? dealTransaction.saleType : "", errors: [] },
  dealType: { value: dealTransaction ? dealTransaction.dealType : "", errors: [] },
  dealershipId: { value: dealTransaction ? dealTransaction.dealershipId : defaultDealership, errors: [] },
  disableRecordingButton: false
}

const RecordingsNew = () => {
  const [state, setState] = useState(defaultState)
  const isFirstRun = useRef(true);

  const {
    submitError,
    startSuccess,
    searchSuccess,
    dealId,
    relatedDealTransactions,
    dealTransactionId,
    customerFirstName,
    customerLastName,
    stockNumber,
    saleType,
    dealType,
    dealershipId,
    disableRecordingButton,
  } = state

  const handleDealTransactionChange = (attribute, value) => {
    setState({ ...state, [attribute]: { value, errors: [] } })
  }

  const handleDealershipChange = ({ selectedItem }) => {
    setState({
      ...state,
      dealershipId: { value: selectedItem.id, errors: [] },
    })
  }

  const handleSubmitErrorClose = () => {
    setState({ ...state, submitError: null })
  }

  const handleTestCamera = async () => {
    gaEvent("Record video", "Test camera click")
    window.open("finsight://record?test=true", "_blank")
  }

  const handleSearch = async () => {
    gaEvent("Record video", "Find deal click")

    try {
      const { response, data } = await postRequest('/recordings/find_deal', {
        dealership_id: dealershipId.value,
        customer_first_name: customerFirstName.value,
        customer_last_name: customerLastName.value,
        stock_number: stockNumber.value,
      })

      const { dealId, dealType, saleType, relatedDealTransactions } = data

      if (response.ok) {
        setState({ ...state,
          searchSuccess: true,
          dealId: dealId,
          dealType: { value: dealType, errors: [] },
          saleType: { value: saleType, errors: [] },
          relatedDealTransactions: relatedDealTransactions,
        })
      } else {
        setState({ ...state, searchSuccess: true })
        window.scrollTo(0, 0)
      }
    } catch(error) {
      setState({ ...state, submitError: "An error occurred. Please contact us at support@go-reahard.com or 866-732-4273 for assistance." })
    }
  }

  const handleStart = async () => {
    gaEvent("Record video", "Start recording click")
    setState( { ...state, disableRecordingButton: true } )
    try {
      const { response, data } = await postRequest('/recordings/start', {
        customer_first_name: customerFirstName.value,
        customer_last_name: customerLastName.value,
        stock_number: stockNumber.value,
        sale_type: saleType.value,
        deal_type: dealType.value,
        dealership_id: dealershipId.value,
      })

      const { errors = {}, dealTransactionId, redirectUrl } = data

      if (response.ok) {
        setTimeout(() => {
          setState({ ...state, startSuccess: true, dealTransactionId })
        }, 3000);

        window.open(redirectUrl, "_blank")
      } else {
        setState( { ...state, disableRecordingButton: false } )
        assignErrors(errors)
        window.scrollTo(0, 0)
      }
    } catch(error) {
      setState({ ...state, submitError: "Transaction did not record. Please contact us at support@go-reahard.com or 866-732-4273 for assistance.", disableRecordingButton: false })
    }
  }

  const handleStop = async () => {
    gaEvent("Record video", "Stop recording click")
    setState( { ...state, disableRecordingButton: true } )
    try {
      const { response, data } = await postRequest('/recordings/stop', {
        deal_transaction_id: dealTransactionId,
      })

      const { errors = {}, redirectUrl } = data

      if (response.ok) {
        window.open(redirectUrl, "_blank")
        window.location.href = '/recordings/new'
      } else {
        setState( { ...state, disableRecordingButton: false } )
        assignErrors(errors)
        window.scrollTo(0, 0)
      }
    } catch(error) {
      setState({ ...state, submitError: "Transaction did not record. Please contact us at support@go-reahard.com or 866-732-4273 for assistance.", disableRecordingButton: false })
    }
  }

  const assignErrors = (errors) => {
    setState({
      ...state,
      submitError: errors.base,
      customerFirstName: { ...customerFirstName, errors: errors.customerFirstName || [] },
      customerLastName: { ...customerLastName, errors: errors.customerLastName || [] },
      stockNumber: { ...stockNumber, errors: errors.stockNumber || [] },
      saleType: { ...saleType, errors: errors.saleType || [] },
      dealType: { ...dealType, errors: errors.dealType || [] },
      dealershipId: { ...dealershipId, errors: errors.dealershipId || [] },
    })
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setState({
      ...state,
      searchSuccess: false,
      dealId: null,
      saleType: { value: "", errors: [] },
      dealType: { value: "", errors: [] }
    })
  }, [customerFirstName.value, customerLastName.value, stockNumber.value])


  const renderDealFragment = () => (
    <React.Fragment>
      <div className="bx--col-lg-8">
        <div className="bx--tile fi--p--24">
          { dealerships.length > 0 ? (
            <React.Fragment>
              <h4>Fill out the deal information to start recording</h4>

              { dealerships.length > 1 && (
                <div className="fi--mt--32">
                  <label className="bx--label">Dealership</label>
                  <Dropdown
                    id="dealership"
                    label=""
                    value={dealershipId.value}
                    items={dealershipItems}
                    errors={dealershipId.errors}
                    onChange={handleDealershipChange}
                    disabled={startSuccess}
                    withEmptyOption
                  />
                </div>
              )}

              <TextInput className="fi--mt--32" name="customerFirstName" label="First name" value={customerFirstName.value} errors={customerFirstName.errors} onChange={handleDealTransactionChange} disabled={startSuccess}/>
              <TextInput className="fi--mt--24" name="customerLastName" label="Last name" value={customerLastName.value} errors={customerLastName.errors} onChange={handleDealTransactionChange} disabled={startSuccess}/>
              <TextInput className="fi--mt--24" name="stockNumber" label="Stock number" value={stockNumber.value} errors={stockNumber.errors} onChange={handleDealTransactionChange} disabled={startSuccess}/>
              { !searchSuccess &&
                <button className="bx--btn bx--btn--primary fi--btn--text-center fi--mr--12 fi--mt--24" onClick={handleSearch} type="button">Continue</button>
              }
              { dealId && searchSuccess &&
                <div className="fi--mt--24 fi--p--24" style={{border: "solid", borderWidth: "0.1px", borderColor: "lightgray"}}>
                  <p className="bx--type-body-short-01">
                    This matches the customer name and stock number of an existing deal. Your new recording will be linked to this deal. If this looks incorrect, change the deal information.
                  </p>
                  <h6 className="fi--mt--12">Deal {dealId}</h6>
                  { relatedDealTransactions.length > 0 &&
                    <h6 className="fi--mt--12">Videos: {relatedDealTransactions.join(", ")}</h6>
                  }
                </div>
              }
              { searchSuccess &&
                <React.Fragment>
                  <RadioButtonGroup
                    className="fi--mt--24 fi--mb--0"
                    items={saleTypeItems}
                    label="Sale Type"
                    name="saleType"
                    selectedValue={saleType.value}
                    errors={saleType.errors}
                    onChange={handleDealTransactionChange}
                    disabled={startSuccess}
                  />

                  <RadioButtonGroup
                    className="fi--mt--24 fi--mb--0"
                    items={dealTypeItems}
                    label="Deal Type"
                    name="dealType"
                    selectedValue={dealType.value}
                    errors={dealType.errors}
                    onChange={handleDealTransactionChange}
                    disabled={startSuccess}
                  />

                  <button
                    disabled={disableRecordingButton}
                    className={`bx--btn bx--btn--${startSuccess ? "danger" : "primary"} fi--btn--text-center fi--mr--12 fi--mt--24`}
                    onClick={startSuccess ? handleStop : handleStart}
                    type="button">{startSuccess ? "Stop" : "Start"} recording
                  </button>
                </React.Fragment>
              }
            </React.Fragment>
          ) : (
            <InlineNotification
              lowContrast
              hideCloseButton
              className="fi--m--0"
              kind="warning"
              statusIconDescription="No dealerships"
              subtitle="You don't have dealerships available to start recording"
              title=""/>
          )}
        </div>
      </div>
    </React.Fragment>
  )

  const renderForm = () => (
    <React.Fragment>
      <div className="bx--grid fi--mx--0 fi--px--0 fi--mt--24">
        <div className="bx--row">
          <div className="bx--col-lg-8">
            <div className="bx--tile fi--mt--0">
              <div>
                <h4>Launch desktop recorder</h4>
                <p className="bx--type-body-short-01 fi--mt--24">Click here only if desktop recorder is NOT already running in your task bar.</p>
              </div>

              <button className="bx--btn bx--btn--primary fi--btn--text-center fi--mr--12 fi--mt--24" onClick={handleTestCamera} type="button">Launch desktop app</button>
            </div>
          </div>

          { renderDealFragment() }

        </div>
      </div>
    </React.Fragment>
  )

  return (
    <div>
      { submitError &&
        <InlineNotification
          kind="error"
          iconDescription="close button"
          subtitle={<span>{submitError}</span>}
          onCloseButtonClick={handleSubmitErrorClose}
          title="Oops..."
        />
      }
      { !submitError && startSuccess &&
        <InlineNotification
          kind="info"
          iconDescription="close button"
          title="Please verify that the count down indicator is in progress at the upper right corner of the screen."
        />
      }
      { renderForm() }
    </div>
  )
}

export default RecordingsNew
