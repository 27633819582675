import React from "react"
import PropTypes from "prop-types"

import RadioButtonGroup from "../../form_items/RadioButtonGroup"
import TextInput from "../../form_items/TextInput"

import {
  performanceItems,
} from "../../../constants/filterItems"

const subPerformanceItems = [
  { id: "lt", label: "Less than or equal to" },
  { id: "gt", label: "Greater than or equal to" },
  { id: "r", label: "Range" }
]

const DealTransactionsFiltersByPerformance = ({name, selectedItems = [], onChange, disabled = false}) => {
  const [type = null, subType = null, value = null] = selectedItems
  const [inputOne, inputTwo] = value ? value.split("-") : []

  const handleTypeChange = (_name, newType) => {
    handleChange([newType, subType, value])
  }

  const handleSubTypeChange = (_name, newSubType) => {
    handleChange([type, newSubType, value])
  }

  const handleInputOneChange = (_name, newInputOne) => {
    const value = subType == "r" ? `${newInputOne}-${inputTwo}` : newInputOne
    handleChange([type, subType, value])
  }

  const handleInputTwoChange = (_name, newInputTwo) => {
    handleChange([type, subType, `${inputOne}-${newInputTwo}`])
  }

  const handleChange = (newSelectedItems) => {
    onChange(name, newSelectedItems)
  }

  const handleClear = () => {
    onChange(name, [])
  }

  return (
    <React.Fragment>
      <div className="fi--d-flex fi--justify-content--space-between">
      <h5 className="fi--mt--24">Performance</h5>
        { type && (<button className="bx--link fi--mt--24" onClick={handleClear}>Clear</button>) }
      </div>
      <RadioButtonGroup
        className="fi--mt--0 fi--mb--0"
        items={performanceItems}
        label=""
        name="performanceType"
        selectedValue={type}
        onChange={handleTypeChange}
        disabled={disabled}
      />
      {(type && type != "top_ten" && type != "bottom_ten") &&
        <div>
          <hr className="fi--mt--16 fi--mb--0"></hr>
          <RadioButtonGroup
            className="fi--mt--0 fi--mb--0"
            items={subPerformanceItems}
            label=""
            name="performanceTypeSubType"
            selectedValue={subType}
            onChange={handleSubTypeChange}
            disabled={disabled}
          />
          { subType &&
            <TextInput
              type="number"
              className="fi--mt--24"
              name="minPerformance"
              label={['gt', 'r'].includes(subType) ? "Min" : "Max"}
              value={inputOne}
              onChange={handleInputOneChange}
              delay={500}
              disabled={disabled}
            />
          }
          { subType == "r" &&
            <TextInput
              type="number"
              className="fi--mt--24"
              name="maxPerformance"
              label="Max"
              value={inputTwo}
              onChange={handleInputTwoChange}
              delay={500}
              disabled={disabled}
            />
          }
        </div>
      }
    </React.Fragment>
  )
}

DealTransactionsFiltersByPerformance.propTypes = {
  name: PropTypes.string,
  selectedItems: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.disabled,
}

export default DealTransactionsFiltersByPerformance
