import React, { useState } from "react"

import {
  Add16
} from '@carbon/icons-react'

import NoFacilitiesFound from "../../../assets/images/icons/search-facilities.svg"
import AddFacilityImage from "../../../assets/images/icons/add-facility.svg";

import buildFacilities from "../../utils/buildFacilities"

import Row from "./Row"
import SearchBar from "../form_items/SearchBar"

const allFacilities = buildFacilities(gon)

const defaultState = {
  searching: false,
  facilities: allFacilities,
}

const FacilitiesList = () => {
  const [state, setState] = useState(defaultState)

  const {
    searching,
    facilities,
  } = state

  const noFacilities = facilities.length === 0

  const handleSearchTermChange = (term) => {
    const downcasedTerm = term.toLowerCase()
    setState({
      searching: term !== "",
      facilities: allFacilities.filter(({name}) => name.toLowerCase().includes(downcasedTerm)),
    })
  }

  const renderNoFacilities = () => (
    <div className="fi--centered-content">
      <img src={AddFacilityImage} width="160" className="fi--mt--96"/>
      <p className="bx--type-expressive-heading-05 fi--mt--64">Add a facility to get started</p>
      <p className="bx--type-body-short-02 fi--mt--24">A facility can be a dealer group, region, or dealership.</p>
    </div>
  )

  const renderNotFound = () => (
    <React.Fragment>
      <p className="bx--type-helper-text-01 fi--mb--16 fi--mt--40 fi--pl--24">{facilities.length} search results</p>
      <div className="fi--centered-content">
        <img src={NoFacilitiesFound} width="160" className="fi--mt--96"/>
        <p className="bx--type-expressive-heading-05 fi--mt--48">No facilities found</p>
        <p className="fi--mt--24">Try adjusting your search or filter to find what you’re looking for.</p>
      </div>
    </React.Fragment>
  )

  const renderFacilities = () => {
    const helperText = searching ? `${facilities.length} search results` : ``

    return(
      <div className="bx--tile fi--mt--24">
        <div className="bx--grid fi--px--8">
          <p className="bx--type-helper-text-01 fi--mb--16">{ helperText }</p>

          { facilities.map(({ id, hierarchyId, name, status}) => (
            <Row
              key={id}
              id={id.split(":")[1]}
              name={name}
              type={id.split(":")[0]}
              offset={searching ? 0 : hierarchyId.split("|").length - 1}
              inactive={status == "inactive"}
            />
          ))}
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="fi--mt--24 fi--d-flex">
        <a href="/facilities/new" className="bx--btn bx--btn--primary" data-gaevent="Add facility click" data-gacategory="Facilities">
          <span>Add facility</span>
          <Add16 className="bx--btn__icon"/>
        </a>

        <SearchBar onChange={handleSearchTermChange} delay={500}/>
      </div>

      { noFacilities ? (searching ? renderNotFound() : renderNoFacilities()) : renderFacilities() }

    </React.Fragment>
  )
}

export default FacilitiesList
