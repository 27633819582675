import React from "react"
import PropTypes from "prop-types"

import {
  Dropdown,
} from 'carbon-components-react';

import sortBy from "../../utils/sortBy"

const emptyDropdownOption = { id: "", label: "" }

const FIDropdown = ({
  id,
  title = "",
  label = "",
  value,
  items,
  className = "",
  onChange,
  errors = [],
  disabled = false,
  withEmptyOption = true,
  light = true,
  sorted = true,
  type="default",
}) => {
  const selectedItem = items.find(item => item.id === value)
  const invalidText = errors.length > 0  ? `This field ${errors.join(", ")}` : ""

  items = sorted ? sortBy(items, "label") : items

  const itemsWithEmptyOption = withEmptyOption ? [emptyDropdownOption, ...items] : items

  return(
    <div className={className}>
      <Dropdown
        type={type}
        light={light}
        id={id}
        titleText={title}
        label={label}
        selectedItem={withEmptyOption ? (selectedItem || emptyDropdownOption) : selectedItem}
        items={selectedItem ? itemsWithEmptyOption : items}
        onChange={onChange}
        invalid={errors.length > 0}
        invalidText={invalidText}
        disabled={disabled}
      />
    </div>
  )
}

FIDropdown.propTypes = {
  type: PropTypes.string,
  light: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
  })),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  withEmptyOption: PropTypes.bool,
  sorted: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default FIDropdown
