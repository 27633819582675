import ReactGA from "react-ga4"

ReactGA.initialize(process.env.GA_TRACK_ID) // eslint-disable-line

const gaEvent = (category, action, label = "") => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

export { gaEvent }
