const sortItems = [
  { id: "manager", label: "F&I Manager" },
  { id: "dealership", label: "Dealership" },
  { id: "date", label: "Date Recorded" },
]

const timeRangeItems = [
  { id: "past_week", label: "Past week" },
  { id: "past_month", label: "Past month" },
  { id: "custom_range", label: "Custom range" },
  { id: "custom_date", label: "Custom date" },
  { id: "all_time", label: "All time" },
]

const durationItems = [
  { id: "0-30", label: "Less than 30 min" },
  { id: "30-60", label: "30-60 min" },
  { id: "60", label: "Longer than 60 min" },
  { id: "shortest_ten", label: "Shortest 10 videos" },
  { id: "longest_ten", label: "Longest 10 videos" },
]

const propertiesItems = [
  { id: "customer_present", label: "Customer present" },
  { id: "customer_consented", label: "Customer consented" },
  { id: "not_opened", label: "Not opened" },
  { id: "vaulted", label: "Vaulted" },
]

const performanceItems = [
  { id: "front_gross", label: "Front-end gross" },
  { id: "back_gross", label: "Back-end gross" },
  { id: "total_gross", label: "Total gross" },
  { id: "top_ten", label: "Top 10 back-end gross" },
  { id: "bottom_ten", label: "Bottom 10 back-end gross" }
]

export {
  sortItems,
  timeRangeItems,
  durationItems,
  propertiesItems,
  performanceItems,
}
