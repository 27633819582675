import React from "react"
import PropTypes from "prop-types"

import RadioButton from "./RadioButton"

const RadioButtonGroup = ({
  items,
  label,
  name,
  selectedValue,
  onChange,
  className,
  errors = [],
  disabled = false,
  readOnly = false,
  horizontal = false,
}) => {
  const klassName = `bx--fieldset ${className}`

  const handleChange = (name, id) => {
    if (!readOnly) {
      onChange(name, id)
    }
  }

  return (
    <fieldset className={klassName}>
      <legend className="bx--label">{label}</legend>
      <div className={`bx--form-item ${horizontal ? 'fi--d-flex-row': ''}`}>
        { items.map(({ id, label }) => (
          <RadioButton
            key={id}
            className={horizontal ? 'fi--mr--32' : ''}
            name={name}
            value={id}
            checked={selectedValue === id}
            onChange={() => { handleChange(name, id) }}
            label={label}
            disabled={disabled}
          />
        ))}
        { errors.length > 0 && <div className="bx--form-requirement">This field {errors.join(", ")}</div> }
      </div>
    </fieldset>
  )
}

RadioButtonGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default RadioButtonGroup
