import React from "react"
import PropTypes from "prop-types"

import {
  TextArea,
} from 'carbon-components-react';

const TextAreaInput = React.forwardRef(({ name, value, label, onChange, className, invalidText = "", rows = 4 }, ref) => {
  const invalid = invalidText != ""

  const handleChange = (event) => {
    onChange(name, event.target.value)
  }

  return (
    <div className={className}>
      <TextArea
        ref={ref}
        invalid={invalid}
        cols={50}
        helperText=""
        id={name}
        invalidText={invalidText}
        labelText={label}
        placeholder=""
        rows={rows}
        value={value}
        onChange={handleChange}
      />
    </div>
  )
})

TextAreaInput.displayName = 'TextAreaInput'

TextAreaInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  invalidText: PropTypes.string,
  rows: PropTypes.number,
}

export default TextAreaInput
