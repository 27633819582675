import React from "react"
import PropTypes from "prop-types"

import {
  CaretLeft32,
  CaretRight32,
} from '@carbon/icons-react'

import { Button } from 'carbon-components-react';

const pagingRange = (currentPage, totalPages, maxPages) => {
  const start = Math.max(currentPage - maxPages + 1, 1)
  const end = Math.min(totalPages, maxPages)

  return Array.from({length: end}, (el, i) => start + i)
}

const PaginationNav = ({ currentPage, totalPages, onChange, maxPages = 3, className = "", disabled = false }) => {
  const pages = pagingRange(currentPage, totalPages, maxPages)

  return (
    <nav className={`bx--pagination-nav fi--pb--0 ${className}`} aria-label="pagination" data-pagination-nav>
      <ul className="bx--pagination-nav__list">
        <li className="bx--pagination-nav__list-item">
          <Button
            disabled={currentPage == 1 || disabled}
            className="bx--pagination-nav__page bx--pagination-nav__page--direction fi--px--0"
            data-page-previous
            onClick={() => onChange(parseInt(currentPage) - 1)}
            renderIcon={CaretLeft32}
            >
          </Button>
        </li>
        {
          pages.map(page => (
            <li key={page} className="bx--pagination-nav__list-item">
              <button
                className={(currentPage != page)? "bx--pagination-nav__page fi--px--0" : "bx--pagination-nav__page bx--pagination-nav__page--active bx--pagination-nav__page--disabled fi--px--0"}
                data-page={page}
                data-page-button
                aria-current={(currentPage == page) && "page"}
                aria-disabled={currentPage == page}
                onClick={() => onChange(page)}
                disabled={disabled}
              >
                <span className="bx--pagination-nav__accessibility-label">page </span>{page}
              </button>
            </li>
          ))
        }
        <li className="bx--pagination-nav__list-item">
          <Button
            disabled={currentPage == totalPages || disabled}
            className="bx--pagination-nav__page bx--pagination-nav__page--direction fi--px--0"
            data-page-next
            onClick={() => onChange(parseInt(currentPage) + 1)}
            renderIcon={CaretRight32}
            >
          </Button>
        </li>
      </ul>
    </nav>
  )
}

PaginationNav.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  maxPages: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default PaginationNav
