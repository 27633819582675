import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Row,
  Column,
  Tag,
  InlineNotification,
} from 'carbon-components-react';

import {
  IbmSecurity16,
} from '@carbon/icons-react'

import CustomerPresentIcon from "../../../assets/images/icons/customer-present.svg";
import NoVideosFoundImage from "../../../assets/images/icons/search-video.svg";

import {
  sortItems,
  timeRangeItems,
  durationItems,
  propertiesItems,
  performanceItems,
} from "../../constants/filterItems"

import Dropdown from "../form_items/Dropdown"
import SearchBar from "../form_items/SearchBar"
import DatePicker from "../form_items/DatePicker"
import PaginationNav from "../form_items/PaginationNav"

import CheckboxFilter from "./filters/Checkbox"
import PerformanceFilter from "./filters/ByPerformance"
import DurationFilter from "./filters/ByDuration"
import { getRequest } from '../../utils/api';
import { gaEvent } from "../../utils/ga";

const currency = new Intl.NumberFormat('en-US',{ style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 }) //eslint-disable-line

const rangeFields = gon.filters.timeRange ? gon.filters.timeRange : []
const dealerGroupItems = gon.dealerGroups.map(({ id, name }) => ({ id, label: name }))
let regionItems = gon.regions.map(({ id, name }) => ({ id, label: name }))
let dealershipItems = gon.dealerships.map(({ id, name }) => ({ id, label: name }))
let managerItems = gon.managers.map(({ id, name }) => ({ id, label: name }))
const saleTypeItems = gon.saleTypes.map(({id, name}) => ({ id, label: name }))
const dealTypeItems = gon.dealTypes.map(({id, name}) => ({ id, label: name }))
let viewerItems = gon.viewers.map(({ id, name }) => ({ id, label: name }))
const enableDownloadTable = gon.enableDownloadTable
const maxTransactions = parseInt(process.env.MAX_TRANSACTIONS) // eslint-disable-line

const defaultState = {
  dealTransactions: gon.dealTransactions,
  timeRange: rangeFields.length >= 1 ? gon.filters.timeRange[0] : "all_time",
  date: rangeFields.length >= 2 ? gon.filters.timeRange[1] : "",
  dateEnd: rangeFields.length == 3 ? gon.filters.timeRange[2] : "",
  sortBy: gon.sortBy || "date",
  currentPage: gon.currentPage,
  totalPages: gon.totalPages,
  totalCount: gon.totalCount,
  searchTerm: gon.searchTerm || "",
  exportStatus: {value: null, message: ""},
  filtering: false,
  filters: {
    dealerGroup: [],
    region: [],
    dealership: [],
    manager: [],
    saleType: [],
    dealType: [],
    videoProperty: [],
    duration: "",
    performance: [],
    viewer: [],
    ...gon.filters,
  },
}

const DealTransactionsList = () => {
  const [state, setState] = useState(defaultState)
  const isFirstRun = useRef(true);
  const searchBarRef = React.createRef()

  const handleVideoClick = (id) => {
    window.location.href = `/deal_transactions/${id}`
  }

  const handleSortChange = ({ selectedItem }) => {
    setState({ ...state,
      currentPage: 1,
      sortBy: selectedItem.id,
    })
  }

  const handleSearchChange = (term) => {
    setState({ ...state, searchTerm: term, currentPage: 1 })
  }

  const handlePageChange = (page) => {
    setState({ ...state, currentPage: page })
  }

  const handleItemsFilterChange = (attribute, selectedItems) => {
    setState({
      ...state,
      currentPage: 1,
      filters: { ...state.filters, [attribute]: selectedItems },
    })
  }

  const handleDealerGroupChange = (_attribute, selectedItems) => {
    setState({
      ...state,
      currentPage: 1,
      filters: {
        ...state.filters,
        dealerGroup: selectedItems,
        region: [],
        dealership: [],
        manager: [],
        viewer: [],
      },
    })
  }

  const handleRegionChange = (_attribute, selectedItems) => {
    setState({
      ...state,
      currentPage: 1,
      filters: {
        ...state.filters,
        region: selectedItems,
        dealership: [],
        manager: [],
        viewer: [],
      },
    })
  }

  const handleDealershipChange = (_attribute, selectedItems) => {
    setState({
      ...state,
      currentPage: 1,
      filters: {
        ...state.filters,
        dealership: selectedItems,
        manager: [],
        viewer: [],
      },
    })
  }

  const handlePerformanceItemChange = (attribute, selectedItems) => {
    let topBottonItem = ["top_ten", "bottom_ten"].includes(selectedItems[0])
    setState({
      ...state,
      currentPage: 1,
      filters: {
        ...state.filters,
        [attribute]: selectedItems,
        duration: topBottonItem ? "" : state.filters.duration
      },
      timeRange: topBottonItem ? "all_time" : state.timeRange,
    })
  }

  const handleDurationItemChange = (attribute, selectedItem) => {
    let performanceSelected = ["shortest_ten", "longest_ten"].includes(selectedItem) ? [] : state.filters.performance
    setState({
      ...state,
      currentPage: 1,
      filters: { ...state.filters, [attribute]: selectedItem, performance: performanceSelected },
    })
  }

  const handleDateChange = (attribute, value) => {
    setState({ ...state, [attribute]: value })
  }

  const timeRangeSearch = () => {
    let range = state.timeRange

    switch(range) {
      case "custom_date":
        return state.date ? `${range},${state.date}` : ""
      case "custom_range":
        return (state.date && state.dateEnd) ? `${range},${state.date},${state.dateEnd}` : ""
    }

    return range
  }

  const updateSearch = () => {
    const params = {
      page: state.currentPage,
      term: state.searchTerm,
      time_range: timeRangeSearch(),
      dealer_group: state.filters.dealerGroup.join(","),
      region: state.filters.region.join(","),
      dealership: state.filters.dealership.join(","),
      manager: state.filters.manager.join(","),
      sale_type: state.filters.saleType.join(","),
      deal_type: state.filters.dealType.join(","),
      video_property: state.filters.videoProperty.join(","),
      duration: state.filters.duration,
      performance: state.filters.performance.join(","),
      viewer: state.filters.viewer.join(","),
      sort_by: state.sortBy,
    }

    let searchParams = []
    Object.keys(params).forEach(key => {
      const value = params[key]

      if (value && String(value).length > 0) {
        searchParams = [...searchParams, `${key}=${value}`]
      }
    })

    window.history.pushState("", "", `?${searchParams.join("&")}`);
    gaEvent("Deal transactions", "Filter and search changed", `${searchParams.join("&")}`)
    fetchSearchResults()
  }

  const selectedFilters = [
    ...state.filters.dealerGroup.map(id => dealerGroupItems.find(dealerGroup => dealerGroup.id == id).label),
    ...state.filters.region.map(id => regionItems.find(region => region.id == id).label),
    ...state.filters.dealership.map(id => dealershipItems.find(dealership => dealership.id == id).label),
    ...state.filters.manager.map(id => managerItems.find(manager => manager.id == id).label),
    ...state.filters.saleType.map(id => saleTypeItems.find(saleType => saleType.id == id).label),
    ...state.filters.dealType.map(id => dealTypeItems.find(dealType => dealType.id == id).label),
    state.filters.duration != "" ? durationItems.find(duration => duration.id == state.filters.duration).label : "",
    ...state.filters.videoProperty.map(id => propertiesItems.find(property => property.id == id).label),
    ...state.filters.viewer.map(id => viewerItems.find(viewer => viewer.id == id).label),

    (!["custom_date", "custom_range"].includes(state.timeRange) ? timeRangeItems.find(timeRange => timeRange.id == state.timeRange).label : ""),
    (state.timeRange == "custom_date" ? state.date : ""),
    (state.timeRange == "custom_range" && state.date && state.dateEnd ? `${state.date} - ${state.dateEnd}` : ""),

    (state.filters.performance.length != 0 && ["bottom_ten", "top_ten"].includes(state.filters.performance[0]) ? performanceItems.find(performance => performance.id == state.filters.performance[0]).label : "")
  ].filter(x => x)

  const fetchSearchResults = async () => {
    try {
      const {response, data } = await getRequest(window.location.href)

      if (response.ok) {
        const { dealTransactions = [], totalPages, totalCount, regions, dealerships, managers, viewers } = data
        dealershipItems = dealerships.map(({ id, name }) => ({ id, label: name }))
        regionItems = regions.map(({ id, name }) => ({ id, label: name }))
        managerItems = managers.map(({ id, name }) => ({ id, label: name }))
        viewerItems = viewers.map(({ id, name }) => ({ id, label: name }))
        setState({ ...state, dealTransactions, totalPages, totalCount, filtering: false })
      }
    } catch(e) {
      console.error("Search failed", e)
    }
  }

  const handleSendEmail = async () => {
    if (gon.totalCount >= maxTransactions) {
      try {
        const {response} = await getRequest(`${window.location.href}&send_email=true`)
        if (response.ok){
          setState({ ...state, exportStatus: {value: "success", message: "Transactions data will be send by email"} })
        }
      } catch(e) {
        setState({ ...state, exportStatus: {value: "error", message: "An error occur sending the email"} })
        console.error("Failed to send mail", e)
      }
    }
  }

  const handleTimeRangeChange = ({ selectedItem }) => {
    setState({ ...state,
      timeRange: selectedItem.id,
      date: "",
      dateEnd: "",
    })
  }

  const handleClearAll = () => {
    searchBarRef.current.clear()
    setState({
      ...state,
      timeRange: "all_time",
      date: "",
      endDate: "",
      sortBy: "date",
      filters: {
        dealerGroup: [],
        region: [],
        dealership: [],
        manager: [],
        saleType: [],
        dealType: [],
        videoProperty: [],
        duration: "",
        performance: [],
        viewer: [],
      },
    })
  }

  const renderNoVideos = () => (
    <div className="fi--centered-content">
      <img width="160" src={NoVideosFoundImage}></img>
      <p className="bx--type-expressive-heading-05 fi--mt--48">No videos found</p>
      <p className="fi--mt--24">Try adjusting your search or filter to find what you’re looking for.</p>
    </div>
  )

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setState({ ...state, exportStatus: {value: null, message: ""}, filtering: true })
    updateSearch()
  }, [
    state.searchTerm,
    state.filters,
    state.currentPage,
    state.timeRange,
    state.date,
    state.dateEnd,
    state.sortBy,
    state.performance
  ])

  const showClearAllButton = selectedFilters.length > 1 || state.timeRange != "all_time" || state.sortBy != "date" || state.performance != ""

  const renderTransaction = (transaction) => (
    <Row key={transaction.id}
      className={`bx--structured-list-row bx--tile bx--tile--clickable fi--px--0 fi--mx--0${transaction.watchedDate && " fi--bg--light-dark"}`}
      onClick={() => { handleVideoClick(transaction.id) }}
    >
      <Column sm={1}>
        <div className="fi--video-preview">
          <img src={transaction.videoThumbnail} className={`fi--width--100${transaction.watchedDate && " fi--watched-preview"} `}></img>
          <p>{transaction.videoDuration}</p>
        </div>
      </Column>
      <Column className="fi--pl--0">
        <p className="bx--type-helper-text-01">{transaction.dealership}</p>
        <p className="bx--type-helper-text-01 fi--mt--4">F&I Manager: {transaction.user}</p>
        <p className="bx--type-expressive-heading-03 fi--mt--8">{transaction.customer} {transaction.customerPresent ? <img className="fi--mt--4 fi--ml--8" src={CustomerPresentIcon} height={16} width={16}/> : ""}</p>
        <p className="bx--type-helper-text-01 fi--mt--8">Stock# {transaction.stockNumber} • Deal# {transaction.dealNumber} • {transaction.dealType}</p>
        <p className="bx--type-helper-text-01 fi--mt--8">{transaction.date} • {transaction.saleType} • {transaction.id}</p>
        { transaction.viewedBy.length > 0 && <p className="bx--type-helper-text-01 fi--mt--8">Viewed by: {transaction.viewedBy.join(", ")}</p> }
      </Column>
      <Column>
        <Row style={{ height: "20%", justifyContent: 'flex-end'}}>
          {transaction.watchedDate &&
            <p className="bx--type-helper-text-01 fi--mt--8 fi--mr--16">Viewed {transaction.watchedDate}</p>
          }
          {transaction.vaulted &&
            <button className="bx--tag bx--tag--gray fi--mr--16">
              <IbmSecurity16 className="bx--btn__icon fi--mr--8"/>
              <span className="bx--tag__label">Vaulted</span>
            </button>
          }
        </Row>
        <Row style={{ height: "80%", justifyContent: 'flex-end', alignItems: 'flex-end'}}>
          {transaction.backGross &&
            <h5 className="fi--mr--16" style={{ fontWeight: "bold" }}>{currency.format(transaction.backGross)}</h5>
          }
        </Row>
      </Column>
    </Row>
  )

  return (
    <Grid fullWidth className="fi--px--16">
      <Row>
        <Column sm={4} md={2} lg={3} className="fi--p--0">
          <div className="bx--tile fi--p--24 fi--height--100">
            { enableDownloadTable &&
              <a
                className="bx--btn bx--btn--tertiary fi--btn--text-center bx--btn--field fi--width--100 fi--mt--0 fi--px--32"
                type="button"
                href={gon.totalCount > 0 && gon.totalCount < maxTransactions ? `${window.location.href}&format=xlsx` : ""}
                onClick={handleSendEmail}>Download as table</a>
            }
            <div className="fi--d-flex fi--justify-content--space-between">
              <h5 className="fi--mt--24">Filters</h5>
              { showClearAllButton && (<button className="bx--link fi--mt--24" onClick={handleClearAll} disabled={state.filtering}>Clear all</button>) }
            </div>
            { (state.filters.performance.lenght == 0 || (!["top_ten", "bottom_ten"].includes(state.filters.performance[0]))) &&
              <React.Fragment>
                <Dropdown
                  className="fi--mt--32"
                  id="time-range"
                  title="Time range"
                  label="Time range"
                  value={state.timeRange}
                  items={timeRangeItems}
                  withEmptyOption={false}
                  onChange={handleTimeRangeChange}
                  disabled={state.filtering}
                />
                {(state.timeRange == "custom_date" || state.timeRange == "custom_range") &&
                  <DatePicker
                    className="fi--mt--16"
                    name="date"
                    label={state.timeRange == "custom_date" ? "Date" : "Start date"}
                    value={state.date}
                    onChange={handleDateChange}
                    disabled={state.filtering}
                  />
                }
                {(state.timeRange == "custom_range") &&
                  <DatePicker
                    className="fi--mt--16"
                    name="dateEnd"
                    label="End date"
                    value={state.dateEnd}
                    onChange={handleDateChange}
                    disabled={state.filtering}
                  />
                }
              </React.Fragment>
            }
            <CheckboxFilter
              items={dealerGroupItems}
              selectedItems={state.filters.dealerGroup}
              onChange={handleDealerGroupChange}
              label="Dealer groups"
              name="dealerGroup"
              buttonLabel="dealer groups"
              disabled={state.filtering}
            />
            <CheckboxFilter
              items={regionItems}
              selectedItems={state.filters.region}
              onChange={handleRegionChange}
              label="Regions"
              name="region"
              buttonLabel="regions"
              disabled={state.filtering}
            />
            <CheckboxFilter
              items={dealershipItems}
              selectedItems={state.filters.dealership}
              onChange={handleDealershipChange}
              label="Dealership"
              name="dealership"
              buttonLabel="dealerships"
              disabled={state.filtering}
            />
            <CheckboxFilter
              items={managerItems}
              selectedItems={state.filters.manager}
              onChange={handleItemsFilterChange}
              label="F&I Managers"
              name="manager"
              buttonLabel="F&I Managers"
              disabled={state.filtering}
            />
            <CheckboxFilter
              items={saleTypeItems}
              selectedItems={state.filters.saleType}
              onChange={handleItemsFilterChange}
              label="Sale type"
              name="saleType"
              buttonLabel="sale types"
              disabled={state.filtering}
            />
            <CheckboxFilter
              items={dealTypeItems}
              selectedItems={state.filters.dealType}
              onChange={handleItemsFilterChange}
              label="Deal type"
              name="dealType"
              buttonLabel="deal types"
              disabled={state.filtering}
            />
            <CheckboxFilter
              items={propertiesItems}
              selectedItems={state.filters.videoProperty}
              onChange={handleItemsFilterChange}
              label="Video properties"
              name="videoProperty"
              buttonLabel="video properties"
              displayedItemsAmount={4}
              disabled={state.filtering}
            />
            <DurationFilter
              name="duration"
              selectedItem={state.filters.duration}
              onChange={handleDurationItemChange}
              disabled={state.filtering}
            />
            <PerformanceFilter
              name="performance"
              selectedItems={state.filters.performance}
              onChange={handlePerformanceItemChange}
              disabled={state.filtering}
            />
            <CheckboxFilter
              items={viewerItems}
              selectedItems={state.filters.viewer}
              onChange={handleItemsFilterChange}
              label="Video watched by"
              name="viewer"
              buttonLabel="viewers"
              disabled={state.filtering}
            />
          </div>
        </Column>
        <Column className="fi--px--32">
          { state.exportStatus.value && state.totalCount >= maxTransactions &&
            <InlineNotification
              kind={state.exportStatus.value}
              iconDescription="close button"
              title={state.exportStatus.message}
            />
          }
          <Row style={{ alignItems: 'flex-end', justifyContent: 'space-between'}} className="fi--mt--24">
            <Column sm={4} md={8} lg={10}>
              <SearchBar
                ref={searchBarRef}
                initialValue={state.searchTerm}
                onChange={handleSearchChange}
                placeholder="Search keywords, like customer name and deal type"
              />
            </Column>
            <Column sm={4} md={3} lg={4}>
              <Row style={{ alignItems: 'center', paddingRight: "18px", justifyContent: "flex-end"}}>
                <label>Sort by:</label>
                <Dropdown
                  type="inline"
                  id="sort"
                  title=""
                  value={state.sortBy}
                  items={sortItems}
                  withEmptyOption={false}
                  onChange={handleSortChange}
                  disabled={state.performance == "top_ten" || state.performance == "bottom_ten" || state.filtering}
                />
              </Row>
            </Column>
          </Row>
          <Row className="fi--d-flex fi--align-items--center fi--mt--24">
            <label className="fi--ml--16">{state.totalCount} results</label>
            { selectedFilters.map(filter => <Tag key={filter} type="cool-gray" className="fi--ml--12" title={filter}> { filter } </Tag>) }
          </Row>
          <div className="fi--mt--32 fi--mb--64">
            {
              state.dealTransactions.map(transaction => (
                renderTransaction(transaction)
              ))
            }
          </div>
          <div>
            {(state.totalCount!=0) ?
              <PaginationNav
                className="fi--mb--64"
                currentPage={state.currentPage}
                totalPages={state.totalPages}
                maxPages={15}
                onChange={handlePageChange}
                disabled={state.filtering}
              />
              :
              renderNoVideos()
            }
          </div>
        </Column>
      </Row>
    </Grid>
  )
}

export default DealTransactionsList
