import React from "react"
import PropTypes from "prop-types"

import RadioButtonGroup from "../form_items/RadioButtonGroup"
import TextInput from "../form_items/TextInput"
import Slider from "../form_items/Slider"

const tripleOptionItems = [
  { id: "yes", label: "Yes" },
  { id: "no", label: "No" },
  { id: "n/a", label: "N/A" }
]

const doubleOptionItems = [
  { id: "yes", label: "Yes" },
  { id: "no", label: "No" },
]

const DealTransactionsChecklistQuestion = ({
  question,
  sectionIndex,
  questionIndex,
  onAnswer,
  filling = false,
}) => {

  const handleAnswer = (attribute, value) => {
    onAnswer(questionIndex, sectionIndex, { ...question, answer: value })
  }

  return (
    <div>
      <legend className="fi--mt--24 bx--type-body-short-01">{question.label}</legend>
      { (question.answerType == "yes_no_na" || question.answerType == "yes_no") &&
        <RadioButtonGroup
          horizontal
          className="fi--my--0"
          items={question.answerType == "yes_no_na" ? tripleOptionItems : doubleOptionItems}
          label=""
          name={`${questionIndex}-${sectionIndex}`}
          readOnly={!filling}
          selectedValue={question.answer}
          onChange={handleAnswer}
        />
      }
      { filling ?
        <div>
          { question.answerType == "number" &&
            <Slider
              name={questionIndex}
              max={20}
              value={parseInt(question.answer) || 0}
              onChange={handleAnswer}
            />
          }
          { question.answerType == "time" &&
            <TextInput
              name={questionIndex}
              label=""
              value={question.answer}
              onChange={handleAnswer}
              placeholder="HH:MM"
            />
          }
        </div>
      :
        <div>
          { question.answerType != "yes_no_na" && question.answerType != "yes_no" &&
            <p>{question.answer}</p>
          }
        </div>
      }
    </div>
  )
}

DealTransactionsChecklistQuestion.propTypes = {
  question: PropTypes.shape({
    label: PropTypes.string,
    answerType: PropTypes.string,
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  sectionIndex: PropTypes.number,
  questionIndex: PropTypes.number,
  onAnswer: PropTypes.func,
  filling: PropTypes.bool,
}

export default DealTransactionsChecklistQuestion
