import React from "react"
import PropTypes from "prop-types"

import {
  PieChart,
  Pie,
  Cell,
} from 'recharts';

const DashboardPieChart = ({ data, title }) => {
  return (
    <div className="fi--d-flex fi--align-items--center">
      <PieChart width={50} height={50}>
        <Pie
          data={data}
          cx={12}
          labelLine={false}
          startAngle={90}
          endAngle={450}
          dataKey="value"
        >
          { data.map((cell, i) => <Cell key={i} fill={cell.color} stroke={cell.color}/> )}
        </Pie>
      </PieChart>
      <span className="bx--type-productive-heading-05">{title}</span>
    </div>
  )
}

DashboardPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    color: PropTypes.string,
  })),
  title: PropTypes.number,
}

export default DashboardPieChart
