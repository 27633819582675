import React, { useState } from "react"
import { v4 as uuid } from "uuid"

import { Button, InlineNotification } from "carbon-components-react"

import ChecklistCreatedImage from "../../../assets/images/icons/checklist-created.svg";

import TextInput from "../form_items/TextInput"
import SectionsForm from "./SectionsForm"

import { postRequest, putRequest } from "../../utils/api"

const { checklistTemplate = {} } = gon

const existingSections = (checklistTemplate.sections || []).map(section => ({
  id: section.id || uuid(),
  title: { value: section.title, errors: []},
  allowComments: { value: section.allowComments, errors: [] },
  questions: (section.questions || []).map(question => ({
    id: question.id || uuid(),
    label: { value: question.label, errors: [] },
    answerType: { value: question.answerType, errors: [] },
    ratingType: { value: question.ratingType, errors: [] },
    optional: { value: question.optional, errors: [] },
  }))
}))

const defaultState = {
  name: { value: checklistTemplate.name || "", errors: [] },
  sections: { value: existingSections, errors: [] },
  submitSuccess: false,
  submitError: null,
  newAction: !checklistTemplate.id,
}

const newSection = () => ({
  id: uuid(),
  title: { value: "", errors: [] },
  allowComments: { value: true, errors: [] },
  questions: [],
})

const ChecklistTemplatesNew = () => {
  const [state, setState] = useState(defaultState)
  const {
    name,
    sections,
    submitSuccess,
    submitError,
    newAction,
  } = state

  const handleChecklistChange = (attribute, value) => {
    setState({ ...state, [attribute]: { value, errors: [] }})
  }

  const handleAddSection = () => {
    document.activeElement.blur()

    setState({
      ...state,
      sections: { value: [...sections.value, newSection()], errors: [] }
    })
  }

  const handleSubmit = async () => {
    document.activeElement.blur()

    try {
      const postOrPut = newAction ? postRequest : putRequest
      const { response, data } = await postOrPut(newAction ? '/checklist_templates' : `/checklist_templates/${checklistTemplate.id}`, {
        checklist_template: {
          name: name.value,
          sections: sections.value.map(section => ({
            id: section.id,
            title: section.title.value,
            allow_comments: section.allowComments.value,
            questions: section.questions.map(question => ({
              id: question.id,
              label: question.label.value,
              answer_type: question.answerType.value,
              rating_type: question.ratingType.value,
              optional: question.optional.value,
            }))
          }))
        }
      })

      const { errors = {} } = data

      if (response.ok) {
        newAction ? setState({ ...state, submitSuccess: true, submitError: null }) : handleUpdate()
      } else {
        assignErrors(errors)
        window.scrollTo(0, 0)
      }
    } catch(e) {
      console.error(e)
      setState({ ...state, submitError: `An error ocurred ${ newAction ? "creating" : "updating"} the checklist. Please try again or contact support` })
    }
  }

  const handleSectionsChange = (newSections) => {
    setState({ ...state, sections: { value: newSections, errors: [] } })
  }

  const handleErrorNotificationClose = () => {
    setState({ ...state, submitError: null })
  }

  const handleUpdate = () => {
    window.location.href = `/checklist_templates?id=${checklistTemplate.id}`
  }

  const handleCancel = () => {
    window.location.href = `/checklist_templates`
  }

  const assignErrors = (errors) => {
    let newState = {
      ...state,
      name: { ...name, errors: errors.name || [] },
    }

    if ((errors.sections || []).includes("is required")) {
      newState = {
        ...newState,
        submitError: "The checklist should have at least 1 section",
      }
    }

    setState(newState)
  }

  const renderChecklistCreated = () => (
    <div className="bx--tile fi--py--96 fi--centered-content">
      <img src={ChecklistCreatedImage} width="160"/>
      <p className="bx--type-expressive-heading-04 fi--mt--64">Checklist successfully created</p>
      <div className="fi--mt--24">
        <a href="/checklist_templates/new" className="bx--btn bx--btn--primary fi--btn--text-center fi--mr--12" data-gaevent="Add another click" data-gacategory="Checklists">Create another checklist</a>
        <a href="/checklist_templates" className="bx--btn bx--btn--secondary fi--btn--text-center fi--ml--12" data-gaevent="Return click" data-gacategory="Checklists">Return to custom checklist</a>
      </div>
    </div>
  )

  const renderChecklistForm = () => (
    <React.Fragment>
      <div className="bx--tile fi--p--24 fi--my--24">
        <p className="bx--type-productive-heading-03">Create custom checklist</p>
      </div>
      <div className="fi--d-flex">
        <div style={{ flex: 1 }}>
          <div className="bx--tile fi--p--24 fi--my--24">
            <p className="bx--type-productive-heading-02">Checklist information</p>
            <TextInput className="fi--mt--32" name="name" label="Checklist name" value={name.value} errors={name.errors} onChange={handleChecklistChange} />
          </div>
          <SectionsForm
            sections={sections.value}
            onSectionsChange={handleSectionsChange}/>
          <div className="bx--modal-footer">
            <Button
              kind="secondary"
              onClick={handleCancel}
              data-gaevent="Cancel checklist"
              data-gacategory="Checklists"
              type="button">
                Cancel
            </Button>
            <Button
              kind="primary"
              onClick={handleSubmit}
              data-gaevent={`${newAction ? 'Create' : 'Update'} checklist`}
              data-gacategory="Checklists"
              type="button">
                { newAction ? "Create checklist" : "Update checklist" }
            </Button>
          </div>
        </div>
        <div className="fi--mt--24">
          <div className="bx--tile fi--text-center fi--ml--12 fi--px--0" style={{zIndex: 1, position: "sticky", top: "50px"}}>
            <Button
              kind="tertiary"
              size="small"
              onClick={handleAddSection}
              data-gaevent="Add another section click"
              data-gacategory="Checklists"
              className="fi--px--12"
              type="button">
                Add section
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <div>
      { submitError &&
        <InlineNotification
          kind="error"
          iconDescription="close button"
          subtitle={<span>{submitError}</span>}
          title={newAction ? "Error creating the checklist" : "Error updating the checklist"}
          onCloseButtonClick={handleErrorNotificationClose}
        />
      }
      { submitSuccess ? renderChecklistCreated() : renderChecklistForm() }
    </div>
  )
}

export default ChecklistTemplatesNew
