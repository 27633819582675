import React, { useState } from "react"
import PropTypes from "prop-types"

import {
  Tooltip,
} from 'carbon-components-react'

import {
  TrashCan16,
  Edit16,
} from '@carbon/icons-react'

import { deleteRequest } from "../../utils/api"

import { gaEvent } from "../../utils/ga";
import DealTransactionsChecklistQuestion from "../deal_transactions/ChecklistQuestion"

const defaultState = {
  tooltip: false,
  deleteError: null,
}

const ChecklistTemplatesDetail = ({ template }) => {
  const [state, setState] = useState(defaultState)

  const handleCancelDelete = () => {
    setState({ ...state, tooltip: false })
  }

  const handleStartDelete = () => {
    setState({ ...state, tooltip: true })
  }

  const handleDelete = async () => {
    gaEvent("Checklist templates", "Delete button click")

    try {
      const { response } = await deleteRequest(`/checklist_templates/${template.id}`)

      if (response.ok) {
        window.location.href = "/checklist_templates"
      } else {
        setState({ ...state, deleteError: "An error ocurred deleting the checklist template" })
      }
    } catch(error) {
      setState({ ...state, deleteError: "An error ocurred deleting the checklist template" })
    }
  }

  const renderFacilities = (facilities) => (
    facilities && facilities.length > 0 ? facilities.join(", ") : <div className="fi--table__placeholder"></div>
  )

  return(
    <React.Fragment>
      <div className="bx--tile fi--px--24 fi--py--24">
        <div className="fi--d-flex fi--justify-content--space-between fi--align-items--center">
          <h3>{template.name}</h3>
          { !template.standard &&
            <span>
              <Tooltip
                open={state.tooltip}
                showIcon={false}
                triggerText={<a className="bx--btn bx--btn--ghost" onClick={handleStartDelete} ><span>Delete</span><TrashCan16 className="bx--btn__icon"/></a>}
              >
                <p>Are you sure you want to delete the checklist? This will permanently remove it and cannot be reversed.</p>
                <div className="bx--tooltip__footer">
                  <button onClick={handleCancelDelete} className="bx--link">Cancel</button>
                  <button onClick={handleDelete} className="bx--btn bx--btn--primary bx--btn--sm" type="button">Delete</button>
                </div>
              </Tooltip>
            </span>
          }
        </div>
      </div>
      <div className="bx--tile fi--p--0 fi--pt--24 fi--mt--24">
        <div className="fi--d-flex fi--px--24 fi--justify-content--space-between fi--align-items--center">
          <h4 className="bx--type-productive-heading-03">Assigned facilities</h4>
          <a className="bx--btn bx--btn--ghost" href={`/checklist_templates/${template.id}/assign`} data-gaevent="Assign click" data-gacategory="Checklist templates" data-galabel={`Checklist template: ${template.id}`}>
            <span>Assign facilities</span>
            <Edit16 className="bx--btn__icon"/>
          </a>
        </div>
        <div className="fi--mt--24 fi--mb--0">
          <table className="fi--table fi--table--facility-index">
            <tbody>
              <tr>
                <th>Dealer group</th>
                <td>{ renderFacilities(template.dealerGroups) }</td>
              </tr>
              <tr>
                <th>Region</th>
                <td>{ renderFacilities(template.regions) }</td>
              </tr>
              <tr>
                <th>Dealership</th>
                <td>{ renderFacilities(template.dealerships) }</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="bx--tile fi--p--0 fi--py--24 fi--mt--24">
        <div className="fi--d-flex fi--px--24 fi--justify-content--space-between fi--align-items--center">
          <h4 className="bx--type-productive-heading-03">Checklist content</h4>
          <a className="bx--btn bx--btn--ghost" href={`/checklist_templates/${template.id}/edit`} data-gaevent="Edit click" data-gacategory="Checklist templates" data-galabel={`Checklist template: ${template.id}`}>
            <span>Edit content</span>
            <Edit16 className="bx--btn__icon"/>
          </a>
        </div>
        <div className="fi--mt--24 fi--px--24 fi--mb--0 fi--border--top">
          { template.sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <h5 className="fi--mt--40">{section.title}</h5>

              { section.questions && section.questions.map((question, questionIndex) => (
                <DealTransactionsChecklistQuestion
                  key={`${sectionIndex}-${questionIndex}`}
                  question={question}
                  sectionIndex={sectionIndex}
                  questionIndex={questionIndex}
                  filling={false}
                />
              ))}
              { section.allowComments && <p className="fi--mt--24 bx--type-body-short-01">{section.title} comments(optional)</p> }
            </div>
          )) }
        </div>
      </div>
    </React.Fragment>
  )
}

ChecklistTemplatesDetail.propTypes = {
  template: PropTypes.any
}

export default ChecklistTemplatesDetail
