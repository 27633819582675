import React from "react"
import PropTypes from "prop-types"

import {
  Slider,
} from 'carbon-components-react';

const SliderInput = ({ name, value, label = "", onChange, className, max = 100 }) => {

  const handleChange = (event) => {
    if (event) {
      onChange(name, event.value)
    }
  }

  return (
    <div className={className}>
      <Slider
        ariaLabelInput="Label for slider value"
        id=""
        labelText={label}
        max={max}
        min={0}
        step={1}
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}

SliderInput.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  max: PropTypes.number,
}

export default SliderInput
