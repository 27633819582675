import React, { useState } from "react"
import {
  InlineNotification,
  Tooltip,
  Row,
} from 'carbon-components-react';

import Assignments from "./Assignments"

import { postRequest } from "../../utils/api"
import buildFacilities from "../../utils/buildFacilities";

import { gaEvent } from "../../utils/ga";

const { template } = gon

const defaultState = {
  submitError: null,
  assignments: { value: template.templateAssignments, errors: [] },
  facilities: buildFacilities(gon),
  setTooltip: false,
}

const ChecklistTemplateAssigns = () => {
  const [state, setState] = useState(defaultState)

  const {
    submitError,
    assignments,
    facilities,
  } = state

  const handleAssignmentsChange = (newAssignments) => {
    setState({ ...state, assignments: { value: newAssignments, errors: [] } })
  }

  const handleSubmit = async () => {
    gaEvent("Checklist templates", "Assign button click")
    try {
      const { response, data } = await postRequest(`/checklist_templates/${template.id}/assign_facilities`, {
        assignments_attributes: state.assignments.value.map(({id, facilityType, facilityId, _destroy}) => (
          {
            id,
            facility_type: facilityType,
            facility_id: facilityId,
            destroy: _destroy,
          }
        )),
      })

      const { errors = {} } = data

      if (response.ok) {
        window.location.href = `/checklist_templates?id=${template.id}`
      } else {
        setState({
          ...state,
          submitError: errors.base || errors["assignments.base"],
        })
        window.scrollTo(0, 0)
      }
    } catch(_e) {
      setState({ ...state, submitError: `An error ocurred assigning the custom checklist to the facilities. Please try again or contact support` })
    }
  }

  const handleCancel = () => {
    gaEvent("Checklist templates", "Cancel assign button click")
    window.location.href = "/checklist_templates"
  }

  const renderForm = () => (
    <React.Fragment>
      <div className="bx--tile fi--px--24 fi--pt--24">
        <h4>{`Assign facilities to ${template.name}`}</h4>
        <p className="bx--type-body-short-01 fi--mt--8">Selecting a parent facility will replace any existing custom checklists.</p>

        { facilities ? 
          <Assignments
            selectedAssignments={assignments.value}
            onAssignmentsChange={handleAssignmentsChange}
            facilities={facilities}
          />
          :
          <div>
            <p className="fi--mt--48 fi--mb--24">
              No facilities available for custom checklist.
            </p>
            <p className="fi--mb--160">
              This may be because you are not subscribed to any facilities. Contact IT admin or Reahard & Associates for assistance.
            </p>
          </div>
        }
        <Row className="fi--justify-content--center">
          <span>
            <Tooltip
              direction="top"
              open={state.setTooltip}
              showIcon={false}
              triggerText={<a></a>}
            >
              <p>Are you sure you want to assign facilities to this checklist? This will replace any existing custom checklists with the new checklist.</p>
              <div className="bx--tooltip__footer">
                <button onClick={() => { setState({ ...state, setTooltip: false }) }} className="bx--link">Cancel</button>
                <button onClick={handleSubmit} className="bx--btn bx--btn--primary bx--btn--sm"
                  type="button">Assign</button>
              </div>
            </Tooltip>
          </span>
        </Row>
      </div>
      <div className="bx--modal-footer">
        <button className="bx--btn bx--btn--secondary" onClick={handleCancel} type="button">Cancel</button>
        <button className="bx--btn bx--btn--primary" onClick={() => { setState({ ...state, setTooltip: true }) }} type="button" disabled={!assignments.value}>Assign</button>
      </div>
    </React.Fragment>
  )

  return (
    <div>
      { submitError &&
        <InlineNotification
          kind="error"
          iconDescription="close button"
          subtitle={<span>{submitError}</span>}
          title={"Error assigning the custom checklist to the facilities"}
        />
      }
      { renderForm() }

    </div>
  )
}

export default ChecklistTemplateAssigns