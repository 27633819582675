import React, { useState } from "react"

import {
  Tile,
  InlineNotification,
  Row,
} from 'carbon-components-react';

import {
  Edit16,
} from '@carbon/icons-react'

import RadioButtonGroup from "../form_items/RadioButtonGroup"
import TextInput from "../form_items/TextInput"

import { putRequest } from "../../utils/api";
import DealTransactionsChecklistQuestion from "./ChecklistQuestion";

const {
  checklist,
  enableChecklistEdit,
} = gon

const transactionTypeItems = [
  { id: "actual", label: "Actual deal" },
  { id: "role-play", label: "Role-play" }
]

const defaultState = {
  filling: false,
  fillSuccess: false,
  submitError: "",
  checklist: checklist,
}

const DealTransactionsChecklist = () => {
  const [state, setState] = useState(defaultState)

  const {
    filling,
    fillSuccess,
    checklist,
  } = state

  const { sections } = checklist

  const handleRecordingTypeChange = (attribute, value) => {
    setState({ ...state, checklist: { ...checklist, recordingType: value } })
  }

  const handleQuestionAnswered = (questionIndex, sectionIndex, answeredQuestion) => {
    const newSections = sections.map((section, sectionI) => {
      if (sectionIndex == sectionI) {
        const newQuestions = section.questions.map((question, questionI) => {
          return questionIndex == questionI ? answeredQuestion : question
        })

        return { ...section, questions: newQuestions }
      } else {
        return section
      }
    })

    setState({ ...state, checklist: { ...checklist, sections: newSections } } )
  }

  const handleCommentsChange = (index, comments) => {
    const newSections = sections.map((section, sectionIndex) => {
      if (sectionIndex == index) {
        return { ...section, comments: comments }
      } else {
        return section
      }
    })

    setState({ ...state, checklist: { ...checklist, sections: newSections } } )
  }

  const handleCancel = () => {
    setState({ ...state, ...defaultState })
  }

  const handleFillSuccessNotificationClose = () => {
    setState({ ...state, fillSuccess: false })
  }

  const handleSubmit = async () => {
    let comments = []
    let answers = []

    checklist.sections.forEach((s, sIndex) => {
      comments.push({ section: sIndex, comments: s.comments })
      const questions = s.questions || []
      questions.forEach((q, qIndex) => {
        answers.push({ section: sIndex, question: qIndex, answer: q.answer })
      })
    })

    try {
      const { response, data } = await putRequest(`/checklists/${checklist.id}`, {
        recording_type: checklist.recordingType,
        comments,
        answers,
      })

      if (response.ok) {
        setState({ ...state, fillSuccess: true, filling: false, checklist: data.checklist })
      }
    } catch(error) {
      setState({ ...state, submitError: "An error ocurred while filling checklist. Please try again or contact support" })
    }
  }

  return (
    <React.Fragment>
      <Tile className={`fi--checklist-wrapper fi--mt--16 ${checklist.completedAt ? "fi--pt--0" : "fi--pt--16"}`}>
        { checklist.completedAt && (checklist.salesRating || checklist.complianceRating) &&
          <Row className="fi--mb--16 fi--py--12 fi--bg--green-15">
            { checklist.salesRating &&
              <p className="fi--ml--32" style={{fontWeight: "bold"}}>{(checklist.salesRating * 100).toFixed(0)}%</p>
            }
            { checklist.salesRating &&
              <p>&nbsp;Sales score</p>
            }
            { checklist.complianceRating &&
              <p className="fi--ml--16" style={{fontWeight: "bold"}}>{(checklist.complianceRating * 100).toFixed(0)}%</p>
            }
            { checklist.complianceRating &&
              <p>&nbsp;Compliance score</p>
            }
          </Row>
        }
        { fillSuccess && (
          <InlineNotification
            kind="success"
            iconDescription="close button"
            subtitle="Checklist was saved successfully!"
            title="Checklist saved."
            onCloseButtonClick={handleFillSuccessNotificationClose}
          />
        )}
        { filling ?
          <div>
            <h3>Fillout Checklist</h3>
            <p className="bx--type-body-short-01 fi--mt--16">Recording type?</p>
          </div>
          :
          <div>
            <div className="fi--d-flex fi--justify-content--space-between">
              <h3>Checklist</h3>
              { enableChecklistEdit && (
                <a onClick={() => { setState({ ...state, filling: true }) }} className="bx--btn bx--btn--ghost">
                  <span>Fill out checklist</span>
                  <Edit16 className="bx--btn__icon"/>
                </a>
              )}
            </div>
            { checklist.reviewer && <p className="bx--type-body-short-01">Last edited by {checklist.reviewer} on {checklist.updatedAt}</p> }
          </div>
        }
        <RadioButtonGroup
          horizontal
          className="fi--my--0"
          items={transactionTypeItems}
          label=""
          name="recordingType"
          readOnly={!filling}
          selectedValue={checklist.recordingType}
          onChange={handleRecordingTypeChange}
        />

        { sections.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <h5 className="fi--mt--40">{section.title}</h5>

            { section.questions && section.questions.map((question, questionIndex) => (
              <DealTransactionsChecklistQuestion
                key={`${sectionIndex}-${questionIndex}`}
                question={question}
                sectionIndex={sectionIndex}
                questionIndex={questionIndex}
                filling={filling}
                onAnswer={handleQuestionAnswered}
              />
            ))}
            { section.allowComments && (
              <React.Fragment>
                <p className="fi--mt--24 bx--type-body-short-01">{section.title} comments(optional)</p>
                { filling ?
                  <TextInput name={sectionIndex} value={section.comments} label="" onChange={handleCommentsChange}/>
                :
                  <p>{section.comments}</p>
                }
              </React.Fragment>
            )}
          </div>
        )) }
      </Tile>
      { filling &&
        <div className="bx--modal-footer">
          <button className="bx--btn bx--btn--secondary" onClick={handleCancel} type="button">Cancel</button>
          <button className="bx--btn bx--btn--primary" onClick={handleSubmit} type="button">Save</button>
        </div>
      }
    </React.Fragment>
  )
}

export default DealTransactionsChecklist
