import React from "react"
import PropTypes from "prop-types"

const NumberInput = ({ name, value, label, onChange, className, errors = [], min = 0, max = 99999999, step = 1 }) => {
  const invalidData = errors.length > 0 ? {"data-invalid": true} : {}
  
  const increment = () => {
    value === "" ? value = 0 : value
    const newValue = parseInt(value) + step
    onChange(name, Math.min(newValue, max))
  }

  const decrement = () => {
    value === "" ? value = 0 : value
    const newValue = parseInt(value) - step
    onChange(name, Math.max(newValue, min))
  }

  const handleChange = (event) => {
    value = event.target.value
    value < 0 ? value = 0 : value
    onChange(name, value)
  }

  return (
    <div className={`bx--number bx--number--light ${className}`} {...invalidData}>
      <label htmlFor={name} className="bx--label">{label}</label>
      <div className="bx--number__input-wrapper">
        <input
          id={name}
          type="number"
          value={value}
          onChange={handleChange}
        />
        <div className="bx--number__controls fi--pr--8">
          <button aria-label="increase number input" className="bx--number__control-btn up-icon" onClick={increment} type="button" aria-live="polite" aria-atomic="true">
            <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 8 4" aria-hidden="true"><path d="M0 4L4 0 8 4z"></path></svg>
          </button>
          <button aria-label="decrease number input" className="bx--number__control-btn down-icon" onClick={decrement} type="button" aria-live="polite" aria-atomic="true">
            <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 8 4" aria-hidden="true"><path d="M8 0L4 4 0 0z"></path></svg>
          </button>
        </div>
      </div>
      { errors.length > 0 && <div className="bx--form-requirement">This field {errors.join(", ")}</div> }
    </div>
  )
}

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default NumberInput
