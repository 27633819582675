import React, { useState } from "react"

import UsersList from "../form_items/ItemsList";
import { getRequest } from "../../utils/api";

import { gaEvent } from "../../utils/ga";

const facilityId = gon.facilityId
const facilityType = gon.facilityType

const defaultState = {
  users: gon.users,
  currentPage: 1,
  totalPages: gon.totalPages,
  totalCount: gon.totalCount,
}

const UserList = () => {
  const [state, setState] = useState(defaultState)

  const users = state.users.map(({ id, name, roles }) => ({ id, name: `${name} (${roles})` }))

  const handlePageChange = async (page) => {
    try {
      const { response, data } = await getRequest(`/facilities/${facilityType}/${facilityId}/users/${page}`)
      const { users = [], totalPages, totalCount, currentPage } = data

      if (response.ok) {
        setState({ ...state, users, totalPages, totalCount, currentPage })
        window.scrollTo(0, 0)
      }
    } catch(e) {
      console.error("Search failed", e)
    }
  }

  const handleUserChange = (userId) => {
    gaEvent("Facilities", "User click", `User: ${userId}`)
    window.location.href = `/users?id=${userId}`
  }

  const renderNoUsers = () => (
    <div className="bx--tile fi--py--24 fi--px--24">
      <h6>0 users</h6>
      <p className="fi--mt--24">Go to the users page to add a user to this facility.</p>
    </div>
  )

  const renderUsers = () => {
    return(
      <UsersList
        totalCount={state.totalCount}
        itemsList={users}
        label="user"
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        onPageChange={handlePageChange}
        onItemChange={handleUserChange}
      >
      </UsersList>
    )
  }

  return (
    <React.Fragment>

      { users.length == 0 ? renderNoUsers() : renderUsers() }

    </React.Fragment>
  )
}

export default UserList
