import React from "react"
import PropTypes from "prop-types"

import { v4 as uuid } from "uuid"

import { Button, Checkbox } from "carbon-components-react"
import { ArrowUp32, ArrowDown32, TrashCan32 } from "@carbon/icons-react"

import TextInput from "../form_items/TextInput"
import QuestionsForm from "./QuestionsForm"

import moveObjectInArray from "../../utils/moveObjectInArray"

const newQuestion = () => ({
  id: uuid(),
  label: { value: "", errors: [] },
  answerType: { value: "", errors: [] },
  ratingType: { value: "", errors: [] },
  optional: { value: false, errors: [] },
})

const SectionsForm = ({ sections, onSectionsChange }) => {
  const handleAddQuestion = (id) => {
    document.activeElement.blur()

    const newSections = sections.map(section => (
      section.id === id ? { ...section, questions: [...section.questions, newQuestion()] } : section
    ))

    onSectionsChange(newSections)
  }

  const handleMoveSection = (from, to) => {
    document.activeElement.blur()

    onSectionsChange(moveObjectInArray(sections, from, to))
  }

  const handleRemoveSection = (id) => {
    onSectionsChange(sections.filter(section => section.id !== id))
  }

  const handleChangeSection = (id, attribute, value) => {
    const newSections = sections.map(section => (
      section.id === id ? { ...section, [attribute]: { value, errors: [] } } : section
    ))

    onSectionsChange(newSections)
  }

  const handleQuestionsChange = (id, newQuestions) => {
    const newSections = sections.map(section => (
      section.id === id ? { ...section, questions: newQuestions } : section
    ))

    onSectionsChange(newSections)
  }

  return (
    <React.Fragment>
      { sections.map((section, index) => (
        <React.Fragment key={section.id}>
          <div style={{zIndex: 1, position: "sticky", top: "50px"}}>
            <div className="bx--tile fi--p--24 fi--my--24">
              <div className="fi--d-flex fi--justify-content--space-between">
                <p className="bx--type-productive-heading-02">Section</p>
                <div>
                  <Button
                    kind="tertiary"
                    size="small"
                    onClick={() => handleAddQuestion(section.id)}
                    className="fi--mr--12 fi--btn--text-center fi--px--12"
                  >Add question</Button>
                  <Button
                    hasIconOnly
                    kind="tertiary"
                    size="small"
                    renderIcon={ArrowUp32}
                    tooltipAlignment="center"
                    tooltipPosition="bottom"
                    iconDescription="Move up"
                    className="fi--mr--12"
                    disabled={index === 0}
                    onClick={() => handleMoveSection(index, index - 1)}
                  />
                  <Button
                    hasIconOnly
                    kind="tertiary"
                    size="small"
                    renderIcon={ArrowDown32}
                    tooltipAlignment="center"
                    tooltipPosition="bottom"
                    iconDescription="Move down"
                    className="fi--mr--12"
                    disabled={index === sections.length - 1}
                    onClick={() => handleMoveSection(index, index + 1)}
                  />
                  <Button
                    hasIconOnly
                    kind="tertiary"
                    size="small"
                    renderIcon={TrashCan32}
                    tooltipAlignment="center"
                    tooltipPosition="bottom"
                    iconDescription="Remove"
                    onClick={() => handleRemoveSection(section.id)}
                  />
                </div>
              </div>
              <TextInput
                className="fi--mt--24"
                name="title"
                label="Section name"
                value={section.title.value}
                errors={section.title.errors}
                onChange={(_attribute, value) => handleChangeSection(section.id, "title", value)} />

              <Checkbox
                className="fi--mt--32"
                id={`section-allow-comments-${section.id}`}
                name={`section-allow-comments-${section.id}`}
                value={`section-allow-comments-${section.id}`}
                checked={section.allowComments.value}
                onChange={(checked) => handleChangeSection(section.id, "allowComments", checked)}
                labelText="Allow comments"
              />
            </div>
          </div>
          <QuestionsForm
            questions={section.questions}
            onQuestionsChange={(questions) => handleQuestionsChange(section.id, questions)}/>

        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

SectionsForm.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.shape({ value: PropTypes.string, errors: PropTypes.arrayOf(PropTypes.string) }),
    allowComments: PropTypes.shape({ value: PropTypes.bool, errors: PropTypes.arrayOf(PropTypes.string) }),
  })),
  onSectionsChange: PropTypes.func,
}

export default SectionsForm
