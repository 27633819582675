import React from 'react';
import {
  Grid,
  Row,
  Column,
} from 'carbon-components-react';

import DealTransactionsSearchBar from "./SearchBar";

import { gaEvent } from "../../utils/ga";

import {
  ArrowRight16,
} from '@carbon/icons-react'

import CustomerPresentIcon from "../../../assets/images/icons/customer-present.svg";

const dealTransactions = gon.dealTransactions

const DealTransactionsList = () => {

  const handleVideoClick = (id, dealership) => {
    gaEvent("Deal transactions", "Recent transaction click", `Deal transaction: ${id}, Dealership: ${dealership}`)
    window.location.href = `/deal_transactions/${id}`
  }

  const handleViewAllRecent = () => {
    gaEvent("Deal transactions", "View all recent click")
    window.location.href = "/deal_transactions/search?time_range=past_week"
  }

  const renderNoVideos = () => (
    <div className="fi--centered-content fi--mt--24 fi--p--24" style={{border: "solid", borderWidth: "0.1px", borderColor: "lightgray"}}>
      <p className="bx--type-expressive-heading-04 fi--my--96">No recent videos available</p>
    </div>
  )

  return (
    <Grid fullWidth>
      <Row>
        <Column>
          <h3>What would you like to watch today?</h3>
        </Column>
      </Row>
      <Row>
        <Column sm={4} md={8} lg={16}>
          <DealTransactionsSearchBar/>
        </Column>
      </Row>
      <hr className="fi--my--64"/>

      <Row>
        <Column className="fi--d-flex fi--justify-content--space-between">
          <div>
            <h4>New videos</h4>
            <p className="bx--type-helper-text-01 fi--mt--12">In the past week.</p>
          </div>
          <div>
            <a onClick={handleViewAllRecent} className="bx--btn bx--btn--secondary">
              <span>View all recent</span>
              <ArrowRight16 className="bx--btn__icon"/>
            </a>
          </div>
        </Column>
      </Row>
      { dealTransactions.length > 0 ?
        <Row>
          {
            dealTransactions.map(transaction => (
              <Column sm={4} md={4} lg={4} className="fi--mt--24" key={transaction.id}>
                <div className="bx--tile bx--tile--clickable" onClick={() => { handleVideoClick(transaction.id, transaction.dealership) }}>
                  <div className="fi--video-preview">
                    <img src={transaction.videoThumbnail} className="fi--width--100"></img>
                    <p>{transaction.videoDuration}</p>
                  </div>
                  <p className="bx--type-helper-text-01 fi--mt--12">{transaction.dealership}</p>
                  <p className="bx--type-helper-text-01 fi--mt--8">FI Mgr: {transaction.manager}</p>
                  <p className="bx--type-productive-heading-03 fi--mt--8 fi--d-flex fi--justify-content--space-between">
                    {transaction.customer} { transaction.customerPresent && <img src={CustomerPresentIcon} width={14} className="fi--mt--4"/> }
                  </p>
                  <p className="bx--type-helper-text-01 fi--mt--8">Stock #{transaction.stockNumber} Deal #{transaction.dealNumber}</p>
                  <p className="bx--type-helper-text-01 fi--mt--8">{transaction.date} | {transaction.dealType}</p>
                </div>
              </Column>
            ))
          }
        </Row>
      :
      renderNoVideos()
      }
    </Grid>
  )
}

export default DealTransactionsList
