import React, { useState } from "react"
import {
  InlineNotification,
  Checkbox,
} from 'carbon-components-react';

import FacilityAddedImage from "../../../assets/images/icons/facility-added.svg";

import NumberInput from "../form_items/NumberInput"
import TextInput from "../form_items/TextInput"
import RadioButtonGroup from "../form_items/RadioButtonGroup"
import Dropdown from "../form_items/Dropdown"

import { gaEvent } from "../../utils/ga";

const dmsItems = [
  { id: "CDK", label: "CDK" },
  { id: "impact_group", label: "Impact Group" },
]

import states from "../../constants/states"
import {
  typeItems,
  videoSaveDurationItems,
  consentItems,
  recordingTimeoutItems,
} from "../../constants/facilityItems"
import { postRequest, putRequest } from "../../utils/api";

const { facility, regions } = gon
const newAction = !facility

const defaultState = {
  submitError: null,
  submitSuccess: false,
  name: { value: facility ? facility.name : "", errors: [] },
  videoSaveDuration: { value: facility ? facility.videoSaveDuration : "", errors: [] },
  type: { value: facility ? facility.type : "", errors: [] },
  licenses: { value: facility ? facility.licenses : "0", errors: [] },
  consentState: { value: facility ? facility.consentState : "", errors: [] },
  contactName: { value: facility ? facility.contactName : "", errors: [] },
  contactEmail: { value: facility ? facility.contactEmail : "", errors: [] },
  contactNumber: { value: facility ? facility.contactNumber : "", errors: [] },
  companyName: { value: facility ? facility.companyName : "", errors: [] },
  streetAddress: { value: facility ? facility.streetAddress : "", errors: [] },
  city: { value: facility ? facility.city : "", errors: [] },
  addressState: { value: facility ? facility.state : "", errors: [] },
  zipCode: { value: facility ? facility.zipCode : "", errors: [] },
  dealerGroupId: { value: facility ? facility.dealerGroupId : "", errors: [] },
  regionId: { value: facility ? facility.regionId : "", errors: [] },
  videoRecordingTimeout: { value: facility ? facility.videoRecordingTimeout : "45", errors: [] },
  dms: { value: facility ? facility.dms : "", errors: [] },
  dmsId: { value: facility ? facility.dmsXid : "", errors: [] },
  recordCustomerNotPresent: facility ? facility.recordCustomerNotPresent : false,
  displayInstallerLink: facility ? facility.displayInstallerLink : false,
}

const stateItems = states.map(({ abbreviation, name }) => ({ id: abbreviation, label: name }))
const dealerGroupItems = gon.dealerGroups.map(({ id, name }) => ({ id, label: name }))

const FacilitiesNew = () => {
  const [state, setState] = useState(defaultState)

  const {
    submitError,
    submitSuccess,
    name,
    videoSaveDuration,
    type,
    licenses,
    consentState,
    contactName,
    contactEmail,
    contactNumber,
    companyName,
    streetAddress,
    city,
    addressState,
    zipCode,
    dealerGroupId,
    regionId,
    videoRecordingTimeout,
    dms,
    dmsId,
    recordCustomerNotPresent,
    displayInstallerLink
  } = state


  let regionItems = regions

  if (dealerGroupId.value) {
    regionItems = regionItems.filter(r => r.dealerGroupId === dealerGroupId.value)
  }

  regionItems = regionItems.map(({ id, name, dealerGroupId }) => ({ id, label: name, dealerGroupId }))

  const handleFacilityChange = (attribute, value) => {
    setState({ ...state, [attribute]: { value, errors: [] }})
  }

  const handleDealerGroupChange = ({ selectedItem }) => {
    setState({
      ...state,
      dealerGroupId: { value: selectedItem.id, errors: [] },
      regionId: { value: "", errors: [] },
    })
  }

  const handleRegionChange = ({ selectedItem }) => {
    setState({
      ...state,
      regionId: { value: selectedItem.id, errors: [] },
      dealerGroupId: { value: selectedItem.dealerGroupId, errors: [] },
    })
  }

  const handleDMSChange = ({ selectedItem }) => {
    setState({
      ...state,
      dms: { value: selectedItem.id, errors: [] },
    })
  }

  const handleRecordCustomerNotPresentChange = () => {
    setState({ ...state, recordCustomerNotPresent: !state.recordCustomerNotPresent })
  }

  const handleDisplayInstallerLinkChange = () => {
    setState({ ...state, displayInstallerLink: !state.displayInstallerLink })
  }

  const handleSubmit = async () => {
    gaEvent("Facilities", newAction ? "Add button click" : "Update button click")
    try {
      const postOrPut = newAction ? postRequest : putRequest
      const { response, data } = await postOrPut(newAction ? '/facilities' : `/facilities/${facility.id}`, {
        name: name.value,
        video_save_duration: videoSaveDuration.value,
        type: type.value,
        licenses: licenses.value,
        consent_state: consentState.value,
        contact_name: contactName.value,
        contact_email: contactEmail.value,
        contact_number: contactNumber.value,
        company_name: companyName.value,
        street_address: streetAddress.value,
        city: city.value,
        state: addressState.value,
        zip_code: zipCode.value,
        region_id: regionId.value,
        dealer_group_id: dealerGroupId.value,
        video_recording_timeout: videoRecordingTimeout.value,
        dms: dms.value,
        dms_xid: dmsId.value,
        record_customer_not_present: recordCustomerNotPresent,
        display_installer_link: displayInstallerLink
      })

      const { errors = {} } = data

      if (response.ok) {
        newAction ? setState({ ...state, submitSuccess: true }) : handleUpdate()
      } else {
        assignErrors(errors)
        window.scrollTo(0, 0)
      }
    } catch(_e) {
      setState({ ...state, submitError: `An error ocurred ${ newAction ? "creating" : "updating"} the facility. Please try again or contact support` })
    }
  }

  const assignErrors = (errors) => {
    setState({
      ...state,
      submitError: errors.base,
      name: { ...name, errors: errors.name || [] },
      videoSaveDuration: { ...videoSaveDuration, errors: errors.videoSaveDuration || [] },
      type: { ...type, errors: errors.type || [] },
      licenses: { ...licenses, errors: errors.licenses || [] },
      consentState: { ...consentState, errors: errors.consentState || [] },
      contactName: { ...contactName, errors: errors.contactName || [] },
      contactEmail: { ...contactEmail, errors: errors.contactEmail || [] },
      contactNumber: { ...contactNumber, errors: errors.contactNumber || [] },
      companyName: { ...companyName, errors: errors.companyName || [] },
      streetAddress: { ...streetAddress, errors: errors.streetAddress || [] },
      city: { ...city, errors: errors.city || [] },
      addressState: { ...addressState, errors: errors.state || [] },
      zipCode: { ...zipCode, errors: errors.zipCode || [] },
      dealerGroupId: { ...dealerGroupId, errors: errors.dealerGroup || [] },
      regionId: { ...regionId, errors: errors.regionId || [] },
      videoRecordingTimeout: { ...videoRecordingTimeout, errors: errors.videoRecordingTimeout || [] },
      dms: { ...dms, errors: errors.dms || [] },
      dmsId: { ...dmsId, errors: errors.dmsXid || [] },
    })
  }

  const handleCancel = () => {
    gaEvent("Facilities", "Cancel button click")
    window.location.href = `/facilities`
  }

  const handleUpdate = () => {
    window.location.href = `/facilities/${facility.type}/${facility.id}`
  }

  const renderFacilityCreated = () => (
    <div className="bx--tile fi--py--96 fi--centered-content">
      <img src={FacilityAddedImage} width="160"/>
      <p className="bx--type-expressive-heading-04 fi--mt--64">Facility successfully added</p>
      <div className="fi--mt--24">
        <a href="/facilities/new" className="bx--btn bx--btn--primary fi--btn--text-center fi--mr--12" data-gaevent="Add another click" data-gacategory="Facilities">Add another facility</a>
        <a href="/facilities" className="bx--btn bx--btn--secondary fi--btn--text-center fi--ml--12" data-gaevent="Return click" data-gacategory="Facilities">Return to facilities</a>
      </div>
    </div>
  )

  const renderForm = () => (
    <React.Fragment>
      <div className="bx--tile fi--p--24">
        <h4>{newAction ? "Add facility" : "Edit facility"}</h4>
        { newAction &&
          <p className="bx--type-body-short-01 fi--mt--8">Once you create a facility, you can assign them to users.</p>
        }
        <TextInput className="fi--mt--32" name="name" label="Name" value={name.value} errors={name.errors} onChange={handleFacilityChange} />

        <RadioButtonGroup
          className="fi--mt--24 fi--mb--0"
          items={typeItems}
          label="Type"
          name="type"
          selectedValue={type.value}
          errors={type.errors}
          onChange={handleFacilityChange}
          disabled={!newAction}
        />

        { (type.value === 'dealership' || type.value === 'region') && (
          <Dropdown
            className="fi--mt--24"
            id="facility-dealer-group"
            title="Assign to dealer group"
            value={dealerGroupId.value}
            items={dealerGroupItems}
            errors={dealerGroupId.errors}
            onChange={handleDealerGroupChange}
            disabled={!newAction}
          />
        )}

        { type.value === 'dealership' && (
          <React.Fragment>
            <Dropdown
              className="fi--mt--24"
              id="facility-region"
              title="Assign to region"
              value={regionId.value}
              items={regionItems}
              errors={regionId.errors}
              onChange={handleRegionChange}
            />
            <RadioButtonGroup
              className="fi--mt--24 fi--mb--0"
              items={videoSaveDurationItems}
              label="Video save duration"
              name="videoSaveDuration"
              selectedValue={videoSaveDuration.value}
              errors={videoSaveDuration.errors}
              onChange={handleFacilityChange}
            />
            <NumberInput
              className="fi--mt--24"
              name="licenses"
              label="Number of licenses"
              value={licenses.value}
              errors={licenses.errors}
              onChange={handleFacilityChange}
            />
            <RadioButtonGroup
              className="fi--mt--24 fi--mb--0"
              items={consentItems}
              label="Consent state"
              name="consentState"
              selectedValue={consentState.value}
              errors={consentState.errors}
              onChange={handleFacilityChange}
            />
            <RadioButtonGroup
              className="fi--mt--24 fi--mb--0"
              items={recordingTimeoutItems}
              label="Video Recording Timeout"
              name="videoRecordingTimeout"
              selectedValue={videoRecordingTimeout.value}
              errors={videoRecordingTimeout.errors}
              onChange={handleFacilityChange}
            />
            <Checkbox
              className="fi--mt--24"
              id="recordCustomerNotPresent"
              checked={recordCustomerNotPresent}
              onChange={handleRecordCustomerNotPresentChange}
              labelText="Record video if customer is not present"
            />
            <Checkbox
              className="fi--mt--12"
              id="displayInstallerLink"
              checked={displayInstallerLink}
              onChange={handleDisplayInstallerLinkChange}
              labelText="Display installer link for IP camera service"
            />
          </React.Fragment>
        )}
        <Dropdown
          className="fi--mt--24"
          id="facility-dms"
          title="Assign DMS System"
          value={dms.value}
          items={dmsItems}
          errors={dms.errors}
          onChange={handleDMSChange}
        />
        { type.value === 'dealership' && dms.value && (
          <TextInput className="fi--mt--32" name="dmsId" label={`${dms.value} ID`} value={dmsId.value} errors={dmsId.errors} onChange={handleFacilityChange}/>
        )}
        { (type.value === 'dealership' || type.value === 'dealer-group') && (
          <React.Fragment>
            <TextInput className="fi--mt--32" name="contactName" label="Contact name (optional)" value={contactName.value} onChange={handleFacilityChange}/>
            <TextInput className="fi--mt--32" name="contactEmail" label="Contact email (optional)" value={contactEmail.value} onChange={handleFacilityChange}/>
            <TextInput className="fi--mt--32" name="contactNumber" label="Contact number (optional)" value={contactNumber.value} onChange={handleFacilityChange}/>
            <TextInput className="fi--mt--32" name="companyName" label="Company name (optional)" value={companyName.value} onChange={handleFacilityChange}/>
            <TextInput className="fi--mt--32" name="streetAddress" label="Street address (optional)" value={streetAddress.value} onChange={handleFacilityChange}/>
            <TextInput className="fi--mt--32" name="city" label="City (optional)" value={city.value} onChange={handleFacilityChange}/>
            <Dropdown
              className="fi--mt--24"
              id="facility-state"
              title="State (optional)"
              value={addressState.value}
              items={stateItems}
              errors={addressState.errors}
              onChange={({selectedItem}) => { handleFacilityChange("addressState", selectedItem.id) }}
            />
            <TextInput className="fi--mt--32" name="zipCode" label="Zip code (optional)" value={zipCode.value} onChange={handleFacilityChange}/>
          </React.Fragment>
        )}
      </div>
      <div className="bx--modal-footer">
        <button className="bx--btn bx--btn--secondary" onClick={handleCancel} type="button">Cancel</button>
        <button className="bx--btn bx--btn--primary" onClick={handleSubmit} type="button">{newAction ? "Add facility" : "Update facility"}</button>
      </div>
    </React.Fragment>
  )

  return (
    <div>
      { submitError &&
        <InlineNotification
          kind="error"
          iconDescription="close button"
          subtitle={<span>{submitError}</span>}
          title={newAction ? "Error creating the facility" : "Error updating the facility"}
        />
      }
      { submitSuccess ? renderFacilityCreated() : renderForm() }

    </div>
  )
}

export default FacilitiesNew
