const answerTypeItems = [
  { id: "yes_no_na", label: "Yes, No, N/A" },
  { id: "yes_no", label: "Yes, No" },
  { id: "number", label: "Number" },
  { id: "time", label: "Time" },
]

const ratingTypeItems = [
  { id: "sales", label: "Sales" },
  { id: "compliance", label: "Compliance" },
]

export {
  answerTypeItems,
  ratingTypeItems
}
