import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

const ENTER_KEY = 13

let timer = null

const InputWithDelay = ({ value: initialValue, onChange, delay = 0, type = "text", placeholder = "", ...inputProps }) => {
  const [value, setValue] = useState(initialValue || "")
  const isFirstRun = useRef(true);

  const handleChange = e => {
    clearTimeout(timer)

    const newValue = e.target.value
    if (type != "number" || newValue == "" || Number.isInteger(parseInt(newValue))) {
      setValue(newValue)
    }
  }

  const triggerChange = () => {
    onChange(value)
  }

  const handleKeyDown = e => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(timer)
      triggerChange()
    }
  }

  const handleUpdate = () => {
    if (delay > 0) {
      timer = setTimeout(triggerChange, delay)
    } else {
      onChange(value)
    }
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    handleUpdate()
  }, [value])

  return (
    <input
      type={type}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      {...inputProps}
    />
  )
}

InputWithDelay.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  delay: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default InputWithDelay
