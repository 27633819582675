import React from "react"
import PropTypes from "prop-types"

const RadioButton = ({ name, label, value, checked, onChange, disabled = false}) => {
  const id = `${name}-${value}`

  return (
    <div className="bx--radio-button-wrapper bx--radio-button-wrapper--label-right fi--mt--8">
      <input
        id={id}
        className="bx--radio-button fi--radio-button-input"
        type="radio"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id} className="bx--radio-button__label">
        <span className="bx--radio-button__appearance"></span>
        <span className="bx--radio-button__label-text">{label}</span>
      </label>
    </div>
  )
}

RadioButton.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default RadioButton
