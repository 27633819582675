import React, { useState, useRef, useEffect } from "react"
import {
  Grid,
  Row,
  Column,
  Tile,
  StructuredListWrapper,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,
  InlineNotification,
  Tooltip,
  Button,
  Tabs,
  Tab,
  Checkbox,
  TextInput,
} from 'carbon-components-react';

import {
  ArrowDown16,
  UserRole32,
  IbmSecurity16,
  Share16,
} from '@carbon/icons-react'

import CopyToClipboard from 'react-copy-to-clipboard';

import CustomerPresentIcon from "../../../assets/images/icons/customer-present.svg";
import VideoBackground from "../../../assets/images/video_thumbnail_placeholder.jpg";
import ArchivedIcon from "../../../assets/images/icons/archived-icon.png";
import DeletedIcon from "../../../assets/images/icons/deleted-icon.png";
import VaultedIcon from "../../../assets/images/icons/vaulted-icon.png";
import Checklist from "./Checklist"
import Comments from "./Comments"
import Logs from "./Logs"
import { postRequest } from "../../utils/api";

const currency = new Intl.NumberFormat('en-US',{ style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 }) //eslint-disable-line

const {
  dealTransactions,
  dealTransaction,
  enableVideoVaultOrRetrieval,
  enableDownloadVideo,
  enableComments,
  currentUser,
  comments,
  timestamp,
  logs,
  watchedDate,
  videoStorageClass,
} = gon

const originUrl = `${window.location.origin}/deal_transactions/${dealTransaction.id}`

let updatedTime = 0

const transactionDataTable = [
  ["Date", dealTransaction.date],
  ["F&I Manager", dealTransaction.user],
  ["Customer", dealTransaction.customer],
  ["Stock #", dealTransaction.stockNumber],
  ["Deal #", dealTransaction.dealNumber],
  ["Transaction #", dealTransaction.id],
  ["Deal type", dealTransaction.dealType],
  ["Sale type", dealTransaction.saleType],
  ["Make", dealTransaction.make],
  ["Model", dealTransaction.model],
  ["VIN", dealTransaction.vin],
  ["Total gross", dealTransaction.enableProducts && dealTransaction.totalGross && currency.format(dealTransaction.totalGross)],
  ["Front gross", dealTransaction.enableProducts && dealTransaction.frontGross && currency.format(dealTransaction.frontGross)],
  ["Back gross", dealTransaction.enableProducts && dealTransaction.backGross && currency.format(dealTransaction.backGross)],
]

const productsDataTable = [
  ["Finance reserve income", currency.format(dealTransaction.financeReserve)],
  ["Vehicle service agreement", currency.format(dealTransaction.vsc)],
  ["Insurance 1", currency.format(dealTransaction.insurance1)],
  ["Insurance 2", currency.format(dealTransaction.insurance2)],
  ["Insurance 3", currency.format(dealTransaction.insurance3)],
  ["Insurance 4", currency.format(dealTransaction.insurance4)],
  ["Other", currency.format(dealTransaction.other)],
]

const defaultState = {
  submitError: null,
  vaultSuccess: false,
  retrieveSuccess: false,
  setTooltip: false,
  newComment: { value: "", errors: [] },
  comments,
  logs: logs || [],
  setTooltipShare: false,
  urlText: originUrl,
  timestampCheck: false,
  time: "00:00",
  copySuccess: false,
  progressTime: "00:00",
}

const DealTransactionsDetail = () => {
  const [state, setState] = useState(defaultState)
  const isFirstRun = useRef(true);
  const videoRef = React.createRef()
  const commentRef = React.createRef()

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    let seconds = calculateTime(state.time)
    setState({ ...state, urlText: seconds === 0 ? originUrl : `${originUrl}?t=${seconds}` })
  }, [state.time])

  const calculateTime = (timeString) => {
    let timeArray = timeString.split(':').reverse()
    let seconds = Number.parseInt(timeArray[0])
    let minutes = Number.parseInt(timeArray[1])
    let hours = Number.parseInt(timeArray[2])
    minutes += hours ? hours * 60 : 0
    seconds += minutes * 60
    seconds = seconds ? seconds : 0

    return seconds
  }

  const hideVideoPlayer = () => {
    return dealTransaction.deleted || (videoStorageClass === "GLACIER" && !dealTransaction.restored);
  }

  const handleTimestampClick = (timeString) => {
    videoRef.current.currentTime = calculateTime(timeString)
  }

  const handleTimestampUrl = () => {
    if (timestamp) {
      videoRef.current.currentTime = timestamp
    }
  }

  const handleTimeChange = (event) => {
    setState({ ...state, time: event.target.value })
  }

  const handletimestampChange = () => {
    if (state.timestampCheck){
      setState({ ...state,
        timestampCheck: false,
        time: "00:00"
      })
    } else {
      setState({ ...state,
        timestampCheck: true,
        time: state.progressTime,
      })
    }
  }

  const handleProgress = (event) => {
    let currentTime = Math.floor(event.target.currentTime)
    if (updatedTime != currentTime) {
      let seconds = currentTime
      let start = seconds < 3600 ? 14 : 11
      let end = seconds < 3600 ? 5 : 8
      let progressTime = new Date(seconds * 1000).toISOString().substr(start, end)

      setState({ ...state, progressTime })
      updatedTime = currentTime
      handleUpdateViewedTime()
    }
  }

  const handleUpdateViewedTime = async () => {
    try {
      await postRequest(`/deal_transactions/${dealTransaction.id}/update_viewed_time`)
    } catch(error) {
      console.error("Couldn't save video viewed time")
    }

  }

  const handleVaultVideo = async () => {
    try {
      setState({ ...state, setTooltip: false })
      dealTransaction.vaulted = true

      const { response } = await postRequest(`/deal_transactions/${dealTransaction.id}/vault`)
      if (response.ok) {
        setState({ ...state, vaultSuccess: true })
      } else {
        dealTransaction.vaulted = false
        window.scrollTo(0, 0)
      }
    } catch(error) {
      setState({ ...state, submitError: "Couldn't vault video, please contact Reahard for more information" })
    }
  }

  const renderVaultedActions = () => {
    if (videoStorageClass === "GLACIER" && !dealTransaction.deleted) {
      return (
        <span className="fi--mr--12">
          <Tooltip
            direction="right"
            open={state.setTooltip}
            showIcon={false}
            triggerText={<Button onClick={() => { setState({ ...state, setTooltip: true }) }} renderIcon={IbmSecurity16}>Retrieve</Button>}
          >
            <p>Retrieving a video from storage will enable it to be played. This can take up to 24 hours.</p>
            <div className="bx--tooltip__footer">
              <button onClick={() => { setState({ ...state, setTooltip: false }) }} className="bx--link">Cancel</button>
              <button onClick={handleRetrieveVideo} className="bx--btn bx--btn--primary bx--btn--sm"
                disabled={dealTransaction.restored || dealTransaction.restoring}
                type="button">Retrieve</button>
            </div>
          </Tooltip>
        </span>
      )
    } else if (!dealTransaction.deleted && !dealTransaction.vaulted && !!dealTransaction.videoUrl) {
      return (
        <span className="fi--mr--12">
          <Tooltip
            open={state.setTooltip}
            showIcon={false}
            triggerText={<Button onClick={() => { setState({ ...state, setTooltip: true }) }} renderIcon={IbmSecurity16} disabled={state.vaultSuccess}>Vault</Button>}
          >
            <p>Vaulting a video incurs a one-time fee. This will store it so it cannot be deleted.</p>
            <div className="bx--tooltip__footer">
              <button onClick={() => { setState({ ...state, setTooltip: false }) }} className="bx--link">Cancel</button>
              <button onClick={handleVaultVideo} className="bx--btn bx--btn--primary bx--btn--sm"
                type="button">Vault</button>
            </div>
          </Tooltip>
        </span>
      )
    }
  }

  const handleRetrieveVideo = async () => {
    if (dealTransaction.restored || dealTransaction.restoring) {
      setState({ ...state, submitError: "The video retrieve is already requested" })
    } else {
      try {
        const { response } = await postRequest(`/deal_transactions/${dealTransaction.id}/retrieve`)

        if (response.ok) {
          dealTransaction.restoring = true;
          setState({ ...state, retrieveSuccess: true, setTooltip: false })
        } else {
          window.scrollTo(0, 0)
        }
      } catch(error) {
        setState({ ...state, submitError: "Couldn't retrieve video, please contact Reahard for more information" })
      }
    }
  }

  const handleCommentsChange = (comments) => {
    setState({...state, comments, newComment: { value: "", errors: [] } })
  }

  const handleAddTimestamp =() => {
    videoRef.current.pause()
    let newText = state.newComment.value + state.progressTime + " "
    setState({ ...state, newComment: { value: newText, errors: [] } })
    commentRef.current.focus()
  }

  const handleCommentChange = (_attribute, value, errors = []) => {
    setState({ ...state, newComment: { value: value, errors: errors } })
  }

  const renderShareSection = () => {
    return(
      <span className="fi--mr--12">
        <Tooltip
          direction="right"
          open={state.setTooltipShare}
          showIcon={false}
          triggerText={<Button onClick={() => { setState({ ...state, setTooltipShare: true, copySuccess: false }) }} renderIcon={Share16}>Share</Button>}
        >
          <a href={state.urlText} className="bx--link fi--mb--24" style={{ inlineSize: "-webkit-fill-available" }}>{state.urlText}</a>
          <Checkbox
            className="fi--mt--12"
            id="timestamp-check"
            name="timestamp-check"
            value="timestamp-check"
            checked={state.timestampCheck}
            onChange={handletimestampChange}
            labelText="Start at time"
          />
          <TextInput
            className={`bx--text--input--time${!state.timestampCheck ? "--disabled" : ""} fi--mt--6`}
            id="timeText"
            name="url"
            labelText=""
            value={state.time}
            disabled={!state.timestampCheck}
            onChange={handleTimeChange}
          />
          <div className="bx--tooltip__footer fi--mt--6">
            <button onClick={() => { setState({ ...state, setTooltipShare: false }) }} className="bx--link">Cancel</button>
            <CopyToClipboard text={state.urlText} onCopy={() => setState({ ...state, copySuccess: true, setTooltipShare: false })}>
              <button className="bx--btn bx--btn--primary bx--btn--sm" type="button">Copy link</button>
            </CopyToClipboard>
          </div>
        </Tooltip>
      </span>
    )
  }

  return (
    <div>
      { state.submitError &&
        <InlineNotification
          kind="error"
          iconDescription="close button"
          subtitle={<span>{state.submitError}</span>}
          title="Error with video"
        />
      }
      { state.vaultSuccess &&
        <InlineNotification
          kind="info"
          iconDescription="close button"
          subtitle="Reahard & Associates will process the one-time fee."
          title="Video vaulted."
        />
      }
      { state.retrieveSuccess &&
        <InlineNotification
          kind="info"
          iconDescription="close button"
          subtitle=""
          title="Video retrieve requested."
        />
      }
      { state.copySuccess &&
        <InlineNotification
          kind="success"
          iconDescription="close button"
          subtitle={state.urlText}
          title="Link copied."
        />
      }

      <Grid fullWidth className="fi--pt--24">
        <Row>
          <Column lg={9}>
            <div>
              <div className="fi--mt--16 fi--video-preview">
                { hideVideoPlayer() === true &&
                  <div className="fi--placeholder--wrapper">
                    <div className="fi--placeholder--icon--wrapper">
                      { (dealTransaction.deleted && <img src={DeletedIcon} />) || (dealTransaction.vaulted && <img src={VaultedIcon} />) || (dealTransaction.archived && <img src={ArchivedIcon} />) }
                      <p>The video file for this deal transaction { (dealTransaction.deleted && <span>is no longer available.  The retention time has expired.</span>) || (dealTransaction.vaulted && <span>has been vaulted</span>) || (dealTransaction.archived && <span>has been archived</span>) }</p>
                    </div>
                  </div>
                }
                <img src={VideoBackground} className="fi--width--100" style={{ display: !dealTransaction.videoUrl && hideVideoPlayer() === false ? 'block' : 'none'}}/>
                { dealTransaction.videoUrl && hideVideoPlayer() === false &&
                  <video ref={videoRef} onLoadedMetadata={handleTimestampUrl} onTimeUpdate={handleProgress} className={'fi--width--100'} src={dealTransaction.videoUrl} controls controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()} />
                }
              </div>
              <Row>
                <h2 className="fi--mt--12 fi--ml--16 bx--type-productive-heading-03">{dealTransaction.customer}</h2>
                {dealTransaction.customerPresent && <img className="fi--mt--16 fi--ml--12" src={CustomerPresentIcon} height={16} width={16}/>}
                {(dealTransaction.deleted && <button className="bx--tag bx--tag--gray fi--mt--12 fi--ml--8"> <img src={DeletedIcon} className="bx--btn__icon fi--mr--8"/><span className="bx--tag__label">Deleted</span></button>) ||
                (dealTransaction.vaulted && <button className="bx--tag bx--tag--gray fi--mt--12 fi--ml--8"> <img src={VaultedIcon} className="bx--btn__icon fi--mr--8"/><span className="bx--tag__label">Vaulted</span></button>) ||
                (dealTransaction.archived && <button className="bx--tag bx--tag--gray fi--mt--12 fi--ml--8"> <img src={ArchivedIcon} className="bx--btn__icon fi--mr--8"/><span className="bx--tag__label">Archived</span></button>)}
                {watchedDate && <button className="bx--tag bx--tag--gray fi--mt--12 fi--ml--8"> <span className="bx--tag__label">Viewed {watchedDate}</span> </button>}
                <Column className="fi--mt--8" style={{display: "flex", justifyContent: "flex-end", height: "50%"}}>
                  <a className="bx--btn bx--btn--primary fi--btn--text-center bx--btn--field" onClick={handleAddTimestamp}>
                    <span>Add timestamp comment</span>
                  </a>
                </Column>
              </Row>
              <p className="fi--mt--0 bx--type-body-long-01">F&I Manager: {dealTransaction.user}</p>
              <p className="fi--mt--8 bx--type-body-long-01">{dealTransaction.dealership}</p>
              <Row className="fi--mt--24 fi--ml--0">
                { enableDownloadVideo && !!videoStorageClass && videoStorageClass !== "GLACIER" && !dealTransaction.deleted &&
                  <a href={dealTransaction.videoUrl} rel="noopener noreferrer" download className="bx--btn bx--btn--primary fi--mr--12">
                    <span>Download</span>
                    <ArrowDown16 className="bx--btn__icon"/>
                  </a>
                }
                { enableVideoVaultOrRetrieval && renderVaultedActions() }
                {renderShareSection()}
              </Row>
            </div>
            <div className="fi--mt--32" style={{ width: '100%' }}>
              <Tabs>
                <Tab
                  href="#"
                  id="tab-1"
                  label="Comments"
                >
                  <Comments
                    comments={state.comments}
                    currentUser={currentUser}
                    dealTransactionId={dealTransaction.id}
                    onTimestampClick={handleTimestampClick}
                    onCommentsChange={handleCommentsChange}
                    newComment={state.newComment}
                    onCommentChange={handleCommentChange}
                    commentRef={commentRef}
                    enableComments={enableComments}
                  />
                </Tab>
                <Tab
                  href="#"
                  id="tab-2"
                  label="Deal details"
                >
                  <Tile className="fi--mt--16 fi--p--0">
                    <h4 className="fi--pt--16 fi--pl--16">Deal details</h4>

                    <StructuredListWrapper ariaLabel="Structured list" className="fi--mt--24 fi--mb--0">
                      <StructuredListBody>
                        { transactionDataTable.map(([title, value]) => (
                          value && (
                            <StructuredListRow key={title}>
                              <StructuredListCell className="fi--py--12">
                                <strong>{title}</strong>
                              </StructuredListCell>
                              <StructuredListCell className="fi--py--12">
                                {value}
                              </StructuredListCell>
                            </StructuredListRow>
                          )
                        )) }
                      </StructuredListBody>
                    </StructuredListWrapper>
                  </Tile>
                </Tab>
                { logs && logs.length > 0 && (
                  <Tab
                    href="#"
                    id="tab-3"
                    label="Logs">
                    <Logs logs={logs}/>
                  </Tab>
                )}
              </Tabs>
            </div>
          </Column>
          <Column lg={7}>
            <Checklist/>

            { dealTransaction.enableProducts &&
              <Tile className="fi--mt--24 fi--p--0">
                <h4 className="fi--pt--16 fi--pl--16">Products sold</h4>

                <StructuredListWrapper ariaLabel="Structured list" className="fi--mt--24 fi--mb--0">
                  <StructuredListBody>
                    { productsDataTable.map(([title, value]) => (
                      value && (
                        <StructuredListRow key={title}>
                          <StructuredListCell className="fi--py--12">
                            <strong>{title}</strong>
                          </StructuredListCell>
                          <StructuredListCell className="fi--py--12">
                            {value}
                          </StructuredListCell>
                        </StructuredListRow>
                      )
                    )) }
                  </StructuredListBody>
                </StructuredListWrapper>
              </Tile>
            }

            { dealTransactions.length > 1 && (
              <Tile className="fi--my--24 fi--p--0">
                <h4 className="fi--p--16">{ dealTransactions.length } Videos</h4>

                <div className="bx--side-nav__items fi--pt--0">
                  { dealTransactions.map((transaction) => (
                    <div key={transaction.id} className="bx--side-nav__item bx--side-nav__link--current fi--border--top fi--height--auto fi--width--100">
                      <a className={`bx--side-nav__link fi--py--16 ${transaction.id === dealTransaction.id ? "bx--side-nav__link--current" : ""}`}
                        href={`/deal_transactions/${transaction.id}`}>
                        <p className="bx--side-nav__link-text">
                          <span>{transaction.customerPresent ? <img src={CustomerPresentIcon} height={14} width={14}/> : <UserRole32 height={14} width={14} />}</span>
                          <strong className="fi--ml--24">{transaction.videoDuration}</strong>
                          <strong className="fi--ml--24">Recorded {transaction.date}</strong>
                        </p>
                      </a>
                    </div>
                  ))}
                </div>
              </Tile>
            )}
          </Column>
        </Row>
      </Grid>
    </div>
  )
}

export default DealTransactionsDetail
