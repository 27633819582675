import React from "react"
import PropTypes from "prop-types"

import {
  CheckmarkFilled16,
} from "@carbon/icons-react"

import {
  Accordion,
  AccordionItem,
} from "carbon-components-react"

const roles = gon.totalRoles

const rolePermissions = [
  { permission: "Upload video",   roles: ["Individual", "Agent", "Lead", "User admin", "Admin"] },
  { permission: "View video",     roles: ["Individual", "Agent", "Lead", "User admin", "Admin"] },
  { permission: "Vault video",    roles: ["Agent", "Lead", "User admin", "Admin"] },
  { permission: "Download video", roles: ["User admin", "Admin"] },
  { permission: "Delete video",   roles: [] },
  { permission: "Add user",       roles: ["User admin", "Admin"] },
  { permission: "Add tags",       roles: ["Individual", "Agent", "Lead", "User admin", "Admin"] },
  { permission: "Excerpt video",  roles: ["Lead", "User admin", "Admin"] },
  { permission: "Share video",    roles: ["Individual", "Agent", "Lead", "User admin", "Admin"] },
  { permission: "Comment",        roles: ["Lead", "User admin", "Admin"] },
]

const UsersPermissionsAccordion = ({ className = "" }) => (
  <Accordion className={className}>
    <AccordionItem title="View role permissions" className="item class">
      <div className="fi--d-flex fi--justify-content">
        <table className="bx--data-table bx--data-table--short">
          <thead>
            <tr>
              <th>
                <span className="bx--table-header-label">Permission</span>
              </th>
              { roles.map(({ name })=> (
                <th key={name}>
                  <span className="bx--table-header-label fi--text-center">{name}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            { rolePermissions.flatMap(({ permission, roles: permittedRoles }) => (
              <tr key={permission}>
                <td>{permission}</td>

                { roles.map(({ name })=> (
                  <td key={name} className="fi--text-center">
                    {permittedRoles.includes(name) && <CheckmarkFilled16 className="fi--icon--green"/>}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AccordionItem>
  </Accordion>
)

UsersPermissionsAccordion.propTypes = {
  className: PropTypes.string,
}


export default UsersPermissionsAccordion
