import React, { useState, useEffect } from "react"
import {
  Row,
  Column,
  Form,
  FormGroup,
  Button,
} from 'carbon-components-react';

import SearchBar from "../form_items/SearchBar"
import Dropdown from "../form_items/Dropdown"
import DatePicker from "../form_items/DatePicker"

import { gaEvent } from "../../utils/ga";

import { timeRangeItems } from "../../constants/filterItems"

const dealTypeItems = gon.dealTypes.map(({id, name}) => ({ id, label: name }))

const defaultState = {
  term: "",
  dealType: "",
  timeRange: "all_time",
  date: "",
  dateEnd: "",
  triggerSearch: false,
}

const DealTransactionsSearchBar = () => {
  const [state, setState] = useState(defaultState)

  const {
    term,
    dealType,
    timeRange,
    date,
    dateEnd,
    triggerSearch,
  } = state

  const handleSearchTermChange = (term, enterPressed) => {
    setState({...state, term, triggerSearch: enterPressed })
  }

  const handleDealTypeChange = ({ selectedItem }) => {
    setState({...state, dealType: selectedItem.id })
  }

  const handleTimeRangeChange = ({ selectedItem }) => {
    setState({ ...state,
      timeRange: selectedItem.id,
      date: "",
      dateEnd: "",
    })
  }

  const handleDateChange = (attribute, value) => {
    setState({ ...state, [attribute]: value })
  }

  const timeRangeSearch = () => {
    let range = timeRange

    switch(range) {
      case "custom_date":
        return date ? `${range},${date}` : ""
      case "custom_range":
        return (date && dateEnd) ? `${range},${date},${dateEnd}` : ""
    }

    return range
  }

  const handleSearch = () => {
    setState({ ...state, triggerSearch: true })
  }

  const submitSearch = () => {
    const searchParams = new URLSearchParams()
    const range = timeRangeSearch()

    term && searchParams.set("term", term)
    dealType && searchParams.set("deal_type", dealType)
    range && searchParams.set("time_range", range)
    searchParams.set("video_property", "customer_present")

    gaEvent("Deal transactions", "Home search", searchParams.toString())
    window.location.href = `/deal_transactions/search?${searchParams.toString()}`
  }

  useEffect(() => {
    if (triggerSearch) {
      submitSearch()
    }
  }, [triggerSearch])

  return (
    <Form>
      <Row style={{ alignItems: 'flex-end'}}>
        <Column sm={4} md={8} lg={8} className="fi--mt--32">
          <FormGroup legendText="Key word" style={{marginBottom: 0}}>
            <SearchBar onChange={handleSearchTermChange} delay={0} size="lg"/>
          </FormGroup>
        </Column>
        <Column sm={4} md={3} lg={4} className="fi--mt--32">
          <Dropdown
            light={false}
            className="fi--mt--4 fi--mr--4 fi--dropdown-notification"
            id="time-range"
            title="Time range"
            label="Time range"
            value={timeRange}
            items={timeRangeItems}
            withEmptyOption={false}
            onChange={handleTimeRangeChange}
          />
        </Column>
        <Column sm={4} md={3} lg={4} className="fi--mt--32">
          <Dropdown
            light={false}
            className="fi--mt--4 fi--mr--4 fi--dropdown-notification"
            id="deal-types"
            title="Deal type"
            label="All deal types"
            value={dealType}
            items={dealTypeItems}
            onChange={handleDealTypeChange}
            withEmptyOption={false}
          />
        </Column>
      </Row>
      <Row style={{ alignItems: 'flex-start'}}>
        <Column sm={4} md={3} lg={3} className="fi--mt--16">
          {(timeRange == "custom_date" || timeRange == "custom_range") &&
            <DatePicker
              light={false}
              className="fi--mt--16"
              name="date"
              label={timeRange == "custom_date" ? "Date" : "Start date"}
              value={date}
              onChange={handleDateChange}
            />
          }
        </Column>
        <Column sm={4} md={3} lg={3} className="fi--mt--16">
          {(timeRange == "custom_range") &&
            <DatePicker
              light={false}
              className="fi--mt--16"
              name="dateEnd"
              label="End date"
              value={dateEnd}
              onChange={handleDateChange}
            />
          }
        </Column>
      </Row>
      <Row className="fi--mt--24">
        <Column>
          <Button
            kind="primary"
            className="fi--btn--text-center"
            onClick={handleSearch}>
            Find video
          </Button>
        </Column>
        <Column>
        </Column>
      </Row>
    </Form>
  )
}

export default DealTransactionsSearchBar
