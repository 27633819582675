import React from "react"
import PropTypes from "prop-types"

const config = {
  DealerGroup: { type: "dealer-group", initials: "DG" },
  Region: { type: "region", initials: "R" },
  Dealership: { type: "dealership", initials: "D" },
}

const FacilityRow = ({ id, name, type, offset, inactive = false }) => (
  <div className="bx--row" key={id}>
    <div className={`bx--col bx--offset-lg-${offset}`}>
      <a href={`/facilities/${config[type].type}/${id}`} className={`fi--facility-item fi--facility-item--${config[type].type}`}>
        <p className="fi--facility-item__initials">
          { config[type].initials }
        </p>
        <div className="bx--row fi--width--100 fi--justify-content--space-between">
          <p className="fi--facility-item__name">
            { name }
          </p>
          { inactive &&
            <p className="fi--mr--12">Inactive</p>
          }
        </div>
      </a>
    </div>
  </div>
)

FacilityRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  offset: PropTypes.number.isRequired,
  inactive: PropTypes.bool,
}

export default FacilityRow
