import React from "react"
import PropTypes from "prop-types"

import {
  Checkbox
} from "carbon-components-react"

const AssignmentCheckbox = ({ id, hierarchyId, name, level, checked = false, disabled = false, onChange }) => (
  <div className={`fi--ml--${level * 32}`} key={id}>
    <Checkbox
      labelText={name}
      id={hierarchyId}
      checked={checked}
      onChange={(checked) => { onChange({ id, hierarchyId, checked })}}
      disabled={disabled} />
  </div>
)

AssignmentCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  hierarchyId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default AssignmentCheckbox