import React from "react"
import PropTypes from "prop-types"

import InputWithDelay from "./InputWithDelay"

const TextInput = ({ name, value, label, onChange, className, errors = [], disabled = false, type="text", delay = 0, placeholder = ""}) => {
  const dataInvalid = errors.length > 0 ? {"data-invalid": true} : {}

  const handleChange = (value) => {
    onChange(name, value)
  }

  return (
    <div className={`bx--text-input-wrapper ${className}`}>
      <label className="bx--label">{label}</label>
      <div className="bx--text-input__field-wrapper" {...dataInvalid}>
        <InputWithDelay
          type={type}
          name={name}
          className="bx--text-input bx--text-input--light"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          delay={delay}
          placeholder={placeholder}
        />
      </div>
      { errors.length > 0 && <div className="bx--form-requirement">This field {errors.join(", ")}</div> }
    </div>
  )
}

TextInput.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  delay: PropTypes.number,
  placeholder: PropTypes.string
}

export default TextInput
