import { camelizeKeys } from "humps"

const postRequest = async (url, body = {}) => {
  return request(url, { method: 'POST',  body: JSON.stringify(body) })
}

const putRequest = async (url, body = {}) => {
  return request(url, { method: 'PUT',  body: JSON.stringify(body) })
}

const getRequest = async (url) => {
  return request(url, { method: 'GET' })
}

const deleteRequest = async (url, body = {}) => {
  return request(url, { method: 'DELETE', body: JSON.stringify(body) })
}

const request = async (url, options) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const requestOptions = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'X-CSRF-Token': csrf,
    },
  }

  const response = await window.fetch(url, requestOptions)
  const data = await response.json()
  return { response, data: camelizeKeys(data) }
}

export {
  postRequest,
  putRequest,
  getRequest,
  deleteRequest,
}
