import React, { useState } from "react"
import {
  InlineNotification,
} from 'carbon-components-react';

import TextInput from "../form_items/TextInput"
import { putRequest } from "../../utils/api";

import { gaEvent } from "../../utils/ga";

const { user } = gon

const defaultState = {
  submitError: null,
  firstName: { value: user.firstName, errors: [] },
  lastName: { value: user.lastName, errors: [] },
  newPassword: { value: "", errors: [] },
  confirmPassword: { value: "", errors: [] },
}

const ProfilesEdit = () => {
  const [state, setState] = useState(defaultState)

  const {
    submitError,
    firstName,
    lastName,
    newPassword,
    confirmPassword,
  } = state

  const handleProfileChange = (attribute, value) => {
    setState({ ...state, [attribute]: { value, errors: [] } })
  }

  const handleSubmit = async () => {
    gaEvent("Profile", "Update click")

    let body = {
      first_name: firstName.value,
      last_name: lastName.value,
    }

    if (newPassword.value != "") {
      body = { ...body, password: newPassword.value }
    }

    try {
      const { response, data } = await putRequest('/profile', body)
      const { errors = {} } = data

      if (response.ok) {
        handleProfileUpdated()
      } else {
        assignErrors(errors)
        window.scrollTo(0, 0)
      }
    } catch(_e) {
      setState({ ...state, submitError: "An error ocurred updating your profile. Please try again or contact support" })
    }
  }

  const assignErrors = (errors) => {
    setState({
      ...state,
      submitError: errors.base,
      firstName: { ...firstName, errors: errors.firstName || [] },
      lastName: { ...lastName, errors: errors.lastName || [] },
      newPassword: { ...newPassword, errors: errors.password || [] },
    })
  }

  const handleCancel = () => {
    gaEvent("Profile", "Cancel update click")
    window.location.href = '/profile'
  }

  const handleProfileUpdated = () => (
    window.location.href = '/profile'
  )

  const renderForm = () => (
    <React.Fragment>
      <div className="bx--tile fi--p--24">
        <h4>Edit user</h4>

        <TextInput className="fi--mt--32" name="firstName" label="First name" value={firstName.value} errors={firstName.errors} onChange={handleProfileChange} />
        <TextInput className="fi--mt--24" name="lastName" label="Last name" value={lastName.value} errors={lastName.errors} onChange={handleProfileChange} />

        <label className="bx--label fi--mt--32">User ID</label>
        <p>{user.id}</p>

        <label className="bx--label fi--mt--32">Email</label>
        <h5>{user.email}</h5>

        <label className="bx--label fi--mt--32">Password</label>
        <h5>**********</h5>

        <label className="bx--label fi--mt--32">New password:</label><br/>
        <label className="bx--label">Password must be at least 10 characters in length and include one uppercase character, one lowercase character, one digit, and one special character.</label>
        <TextInput name="newPassword" type="password" value={newPassword.value} errors={newPassword.errors} onChange={handleProfileChange} />
        <TextInput className="fi--mt--32" name="confirmPassword" label="Confirm password" type="password" value={confirmPassword.value} errors={confirmPassword.errors} onChange={handleProfileChange} />

        <label className="bx--label fi--mt--32">Roles</label>
        <h5>{user.roles}</h5>

      </div>

      <div className="bx--modal-footer">
        <button className="bx--btn bx--btn--secondary" onClick={handleCancel} type="button">Cancel</button>
        <button
          className="bx--btn bx--btn--primary"
          onClick={handleSubmit}
          type="button"
          disabled={newPassword.value != confirmPassword.value}>
          Update
        </button>
      </div>
    </React.Fragment>
  )

  return (
    <div>
      { submitError &&
        <InlineNotification
          kind="error"
          iconDescription="close button"
          subtitle={<span>{submitError}</span>}
          title="Error updating the profile"
        />
      }
      { renderForm() }

    </div>
  )
}

export default ProfilesEdit
