import React from "react"
import PropTypes from "prop-types"

import {
  InlineNotification,
} from 'carbon-components-react';

const NotificationsShow = ({ kind, message }) => (
  <InlineNotification
    kind={kind}
    title={message}
  />
)

NotificationsShow.propTypes = {
  kind: PropTypes.string,
  message: PropTypes.string,
}

export default NotificationsShow
