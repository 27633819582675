import React from "react"
import PropTypes from "prop-types"

import {
  Checkbox,
} from "carbon-components-react"

const CheckboxList = ({ items, label, name, className, selectedItems = [], clearable = false, onChange, errors = [], disabled = false }) => {
  const handleChange = ({ id, name }) => {
    if(selectedItems.includes(id)) {
      onChange(name ,selectedItems.filter(item => item != id))
    } else {
      onChange(name, [...selectedItems, id])
    }
  }

  const handleClear = () => {
    onChange(name, [])
  }

  const showClearButton = clearable && selectedItems.length > 0

  return (
    <fieldset className={className}>
      <div className="fi--d-flex fi--justify-content--space-between">
        <h5 className="fi--mt--0">{label}</h5>
        { showClearButton && (<button className="bx--link" onClick={handleClear} disabled={disabled}>Clear</button>) }
      </div>

      <div className="bx--form-item">
        { items.map(({ id, label }) => (
          <Checkbox
            id={`${name}-${id}`}
            key={id}
            name={name}
            value={id}
            checked={selectedItems.includes(id.toString())}
            onChange={() => { handleChange({ id: id.toString(), name })}}
            labelText={label}
            disabled={disabled}
          />
        ))}
        { errors.length > 0 && <div className="bx--form-requirement">This field {errors.join(", ")}</div> }
      </div>
    </fieldset>
  )
}

CheckboxList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.isRequired,
    label: PropTypes.string.isRequired,
  })),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  selectedItems: PropTypes.array,
  clearable: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
}

export default CheckboxList
