import React from "react"
import PropTypes from "prop-types"

import {
  Email16,
  Edit16,
} from '@carbon/icons-react'

import { postRequest } from "../../utils/api";

import { gaEvent } from "../../utils/ga";

const UsersDetail = ({ user }) => {

  const handleResendInvite = async () => {
    gaEvent("Users", "Resend invite click")
    await postRequest(`/users/${user.id}/resend_invite`)
    window.location.href = `/users?id=${user.id}`
  }

  return(
    <div className="bx--tile fi--p--0">
      <div className="fi--px--24 fi--pt--24">
        <div className="fi--d-flex fi--justify-content--space-between">
          <h2>{user.name}</h2>
          <div>
            <a className="bx--btn bx--btn--ghost" rel="nofollow" onClick={handleResendInvite}>
              <span>Resend Invite</span>
              <Email16 className="bx--btn__icon"/>
            </a>
            <a className="bx--btn bx--btn--ghost" href={`/users/${user.id}/edit`} data-gaevent="Edit click" data-gacategory="Users" data-galabel={`User: ${user.id}`}>
              <span>Edit</span>
              <Edit16 className="bx--btn__icon"/>
            </a>
          </div>
        </div>
      </div>
      <div className="fi--mt--48">
        <h5 className="fi--px--24">Information</h5>
        <div className="fi--mt--24">
          <table className="fi--table fi--table--facility-index">
            <tbody>
              <tr>
                <th>Name</th>
                <td>{user.name}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{user.email}</td>
              </tr>
              <tr>
                <th>Password</th>
                <td>**************</td>
              </tr>
              <tr>
                <th>Roles</th>
                <td>{user.roles}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{user.status}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="fi--mt--48">
        <h5 className="fi--px--24">Subscriptions</h5>
        <div className="fi--mt--24">
          <table className="fi--table fi--table--facility-index">
            <tbody>
              <tr>
                <th>Dealer group</th>
                <td>
                  {
                    user.dealerGroups.length > 0 ? user.dealerGroups.join(", ")
                    :
                    <div className="fi--table__placeholder"></div>
                  }
                </td>
              </tr>
              <tr>
                <th>Region</th>
                <td>
                  {
                    user.regions.length > 0 ? user.regions.join(", ")
                    :
                    <div className="fi--table__placeholder"></div>
                  }
                </td>
              </tr>
              <tr>
                <th>Dealership</th>
                <td>
                  {
                    user.dealerships.length > 0 ? user.dealerships.join(", ")
                    :
                    <div className="fi--table__placeholder"></div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

UsersDetail.propTypes = {
  user: PropTypes.any
}

export default UsersDetail
