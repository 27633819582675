import React from "react"
import PropTypes from "prop-types"

import SubscriptionCheckbox from "./SubscriptionCheckbox"

const UsersSubscriptions = ({
  individualSubscriptions = false,
  onlyIndividual = false,
  disabled = false,
  selectedSubscriptions = [],
  onSubscriptionsChange,
  facilities = [],
}) => {
  let activeSubscriptions = selectedSubscriptions.filter(subscription => subscription._destroy != "1").map(selectedSubscription => (
    facilities.find(f => f.id == `${selectedSubscription.facilityType}:${selectedSubscription.facilityId}`).hierarchyId
  ))

  let activeFacilities = facilities.filter(({ hierarchyId }) => {
    const parts = hierarchyId.split("|")
    return activeSubscriptions.find(s => parts.every((part) => s.split("|").includes(part)))
  })

  const handleSubscriptionChecked = ({ id, checked }) => {
    const [facilityType, facilityId] = id.split(":")

    const deactivatedSubscriptions = activeSubscriptions.filter(s => s.includes(id)).map(s => s.split("|")[0])

    const newSelectedSubscriptions = selectedSubscriptions.map(subscription => {
      const selectedId = `${subscription.facilityType}:${subscription.facilityId}`

      if (deactivatedSubscriptions.includes(selectedId)) {
        return { ...subscription, _destroy: "1" }
      } else if(selectedId === id) {
        const { id, facilityId, facilityType } = subscription
        return { id, facilityId, facilityType }
      } else {
        return subscription
      }
    })

    const existingSubscription = newSelectedSubscriptions.find(s =>  id === `${s.facilityType}:${s.facilityId}`)

    const updatedSubscriptions = existingSubscription ? newSelectedSubscriptions : [...newSelectedSubscriptions, { facilityType, facilityId }]
    const newSubscriptions = updatedSubscriptions.filter(s => !s.id || (s.id && s._destroy != "1"))

    const newFacilities = facilities.map(f => {
      const availableLicenses = f.availableLicenses + (checked ? -1 : 1)
      return (f.id === id && individualSubscriptions) ? { ...f, availableLicenses } : f
    })

    onSubscriptionsChange(newSubscriptions, newFacilities)
  }

  return (
    <React.Fragment>
      <div className="bx--form__item fi--mt--24">
        <div className="bx--form__helper-text">Subscriptions</div>
        <label>Purchased licenses go to active individuals (F&I Managers). Leads don’t require a license.</label>

        <div className="fi--mt--24">
          { facilities.map(facility => (
            <SubscriptionCheckbox
              key={facility.id}
              id={facility.id}
              hierarchyId={facility.hierarchyId}
              name={facility.id.split(":")[0] !== "Dealership" ? facility.name : `${facility.name} (${facility.availableLicenses} out of ${facility.totalLicenses} licenses available)`}
              level={facility.hierarchyId.split("|").length - 1}
              checked={activeFacilities.includes(facility)}
              onChange={handleSubscriptionChecked}
              disabled={
                disabled ||
                (facility.id.split(":")[0] !== "Dealership" && onlyIndividual) ||
                (!activeFacilities.includes(facility) && facility.availableLicenses < 1 && individualSubscriptions)
              }
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

UsersSubscriptions.propTypes = {
  individualSubscriptions: PropTypes.bool,
  onlyIndividual: PropTypes.bool,
  disabled:  PropTypes.bool,
  selectedSubscriptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  })),
  facilities: PropTypes.array,
  onSubscriptionsChange: PropTypes.func.isRequired,
}

export default UsersSubscriptions
