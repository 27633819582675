import React from "react"
import PropTypes from "prop-types"

import AssignmentCheckbox from "./AssignmentCheckbox"

const Assignments = ({
  selectedAssignments = [],
  onAssignmentsChange,
  facilities = [],
}) => {
  let activeAssignments = selectedAssignments.filter(assignment => assignment._destroy != "1").map(selectedAssignment => (
    facilities.find(f => f.id == `${selectedAssignment.facilityType}:${selectedAssignment.facilityId}`).hierarchyId
  ))

  let activeFacilities = facilities.filter(({ hierarchyId }) => {
    const parts = hierarchyId.split("|")
    return activeAssignments.find(s => s.split("|").every(part => parts.includes(part)))
  })

  const handleAssignmentChecked = ({ id }) => {
    const [facilityType, facilityId] = id.split(":")

    const deactivatedAssignments = activeAssignments.filter(s => s.includes(id)).map(s => s.split("|")[0])

    const newSelectedAssignments = selectedAssignments.map(assignment => {
      const selectedId = `${assignment.facilityType}:${assignment.facilityId}`

      if (deactivatedAssignments.includes(selectedId)) {
        return { ...assignment, _destroy: "1" }
      } else if(selectedId === id) {
        const { id, facilityId, facilityType } = assignment
        return { id, facilityId, facilityType }
      } else {
        return assignment
      }
    })

    const existingAssignment = newSelectedAssignments.find(s =>  id === `${s.facilityType}:${s.facilityId}`)

    const updatedAssignments = existingAssignment ? newSelectedAssignments : [...newSelectedAssignments, { facilityType, facilityId }]
    const newAssignments = updatedAssignments.filter(s => s.id || (!s.id && s._destroy != "1"))

    onAssignmentsChange(newAssignments)
  }

  return (
    <React.Fragment>
      <div className="bx--form__item fi--mt--24">
        <div className="fi--mt--24">
          { facilities.map(facility => (
            <AssignmentCheckbox
              key={facility.id}
              id={facility.id}
              hierarchyId={facility.hierarchyId}
              name={`${facility.name} - ${facility.checklist ? facility.checklist : "default checklist"}`}
              level={facility.hierarchyId.split("|").length - 1}
              checked={activeFacilities.includes(facility)}
              onChange={handleAssignmentChecked}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

Assignments.propTypes = {
  selectedAssignments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  })),
  facilities: PropTypes.array,
  onAssignmentsChange: PropTypes.func.isRequired,
}

export default Assignments