import React from "react"
import PropTypes from "prop-types"
import {
  DatePicker,
  DatePickerInput,
} from 'carbon-components-react';

const Date = ({ name = "", value, label = "", onChange, className, light = true, disabled = false }) => {
  const handleChange = (eventOrDate) => {
    const date = eventOrDate.target
                  ? eventOrDate.target.value
                  : eventOrDate[0]

    onChange(name, date.toLocaleDateString("en-US"))
  }

  return (
    <DatePicker light={light} dateFormat="m/d/Y" datePickerType="single" className={className} onChange={handleChange}>
      <DatePickerInput
        id={name}
        placeholder="mm/dd/yyyy"
        labelText={label}
        type="text"
        style={{ width: '100%' }}
        value={value}
        name={name}
        onChange={() => {}}
        disabled={disabled}
      />
    </DatePicker>
  )
}

Date.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  light: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Date
