import React from "react"
import PropTypes from "prop-types"
import {
  AreaChart, Area, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
import { Tile } from "carbon-components-react";

const renderTooltipContent = (o) => {
  const { payload } = o;
  const names = {
    videosCreated: 'Videos created',
    videosViewed: 'Videos viewed',
    dealsLogged: 'Deals logged',
    dealsReviewed: 'Deals reviewed'
  }

  return (
    <div className="customized-tooltip-content" style={{backgroundColor: "rgba(255, 255, 255, .8)"}}>
      <ul className="list">
        {
          payload.map((entry, index) => (
            <li key={`item-${index}`} className="fi--mt--2">
              {`${names[entry.name]}: ${entry.value}`}
           </li>
          ))
        }
      </ul>
    </div>
  );
};

const DashboardVideosOverTime = ({ data }) => {
  const legendPayload = [
    { id: '1', value: 'Videos created', type: 'square', color: '#c9e9cc'},
    { id: '2', value: 'Videos viewed', type: 'square', color: '#347e3c'},
    { id: '3', value: 'Deals logged', type: 'square', color: '#bfd7f2'},
    { id: '4', value: 'Deals reviewed', type: 'square', color: '#1e5695'},
  ]

  return (
    <Tile className="fi--p--48">
      <p className="bx--type-productive-heading-02 fi--mb--24 fi--text-center">Videos over time</p>

      <ResponsiveContainer width="100%" height={450}>
        <AreaChart
          data={data}
          margin={{
            top: 10, right: 30, left: 30, bottom: 30,
          }}
        >
          <XAxis dataKey="day" label={{ value: 'Time', position: 'insideBottom', offset: -30 }}/>
          <YAxis label={{ value: 'Number of videos', angle: -90, position: 'insideLeft', offset: -10 }}/>
          <Tooltip content={renderTooltipContent} />
          <Area type="linear" dataKey="videosCreated" stackId="1" stroke="#255a2b" fill="#c9e9cc" fillOpacity={0.6} />
          <Area type="linear" dataKey="videosViewed" stackId="2" stroke="#255a2b" fill="#347e3c" fillOpacity={0.6} />
          <Area type="linear" dataKey="dealsLogged" stackId="3" stroke="#153e6a" fill="#bfd7f2" fillOpacity={0.6} />
          <Area type="linear" dataKey="dealsReviewed" stackId="4" stroke="#153e6a" fill="#1e5695" fillOpacity={0.6}/>
          <Legend verticalAlign="top" height={36} payload={legendPayload}/>
        </AreaChart>
      </ResponsiveContainer>
    </Tile>
  )
}

DashboardVideosOverTime.propTypes = {
  data: PropTypes.any
}

export default DashboardVideosOverTime
