
const moveObjectInArray = (array, from, to) => (
  array.map((obj, index) => {
    if (index === to) return array[from]
    if (index === from) return array[to]
    return obj
  })
)

export default moveObjectInArray
