import React from "react"
import PropTypes from "prop-types"

import PaginationNav from "./PaginationNav"

const ItemsList = ({
  totalCount,
  itemsList,
  itemId = 0,
  label,
  currentPage,
  totalPages,
  onPageChange,
  onItemChange = {},
  maxPages = 3,
  className = ""
}) => {

  return (
    <React.Fragment>
      <div className="bx--tile fi--pt--16 fi--px--0 fi--pb--0">
        <h6 className="fi--mb--16 fi--ml--16">{totalCount} {totalCount != 1 ? `${label}s` : label }</h6>
        <ul className="bx--side-nav__items fi--pt--0">
          {
            itemsList.map(item => (
              <li  key={item.id} className={`bx--side-nav__item${itemId == item.id ? " bx--side-nav__link--current" : ""} fi--border--top fi--height--auto fi--width--100`}>
                <a className={`bx--side-nav__link fi--py--16${itemId == item.id ? " bx--side-nav__link--current" : ""}`} onClick={() => onItemChange(item.id)}>
                  <span className="bx--side-nav__link-text fi--font-weight--normal">{item.name}</span>
                </a>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="bx--tile fi--border--top fi--p--0" style={{ minHeight: "0px" }}>
        {(totalCount > 0 ) &&
          <PaginationNav
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={onPageChange}
            maxPages={maxPages}
            className={className}
          />
        }
      </div>
    </React.Fragment>
  )
}

ItemsList.propTypes = {
  totalCount: PropTypes.number.isRequired,
  itemsList: PropTypes.array.isRequired,
  itemId: PropTypes.number,
  label: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  maxPages: PropTypes.number,
  className: PropTypes.string,
  onItemChange: PropTypes.func.isRequired,
}

export default ItemsList
