import React from "react"
import PropTypes from "prop-types"

const Toggle = ({ id, label, onLabel, offLabel, onChange, className, checked }) => (
  <div className={className}>
    <input className="bx--toggle-input bx--toggle-input--small" id={id} type="checkbox" onChange={onChange} checked={checked}/>
      <label className="bx--toggle-input__label" htmlFor={id}>
        {label}
        <span className="bx--toggle__switch">
          <svg className="bx--toggle__check" width="6px" height="5px" viewBox="0 0 6 5">
          <path d="M2.2 2.7L5 0 6 1 2.2 5 0 2.7 1 1.5z" />
          </svg>
          <span className="bx--toggle__text--off" aria-hidden="true">{offLabel}</span>
          <span className="bx--toggle__text--on" aria-hidden="true">{onLabel}</span>
        </span>
      </label>
  </div>
)

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onLabel: PropTypes.string.isRequired,
  offLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
}

export default Toggle
