import React from "react"
import PropTypes from "prop-types"
import {
  Tile,
} from 'carbon-components-react'
;
const DealTransactionLogs = ({
  logs = [],
}) => (
  <Tile className="fi--p--0">
    <table className="fi--table">
      <thead>
        <tr>
          <th>
            Timestamp
          </th>
          <th>
            Description
          </th>
        </tr>
      </thead>
      <tbody>
        { logs.map(log => (
          <tr key={log.id}>
            <td>
              {log.createdAt}
            </td>
            <td style={{wordBreak : "break-word"}}>
              {log.description.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </Tile>
)

DealTransactionLogs.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      createdAt: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
}

export default DealTransactionLogs
