import React, {useState} from "react"
import PropTypes from "prop-types"
import {
  Row,
  Column,
  Tooltip,
} from 'carbon-components-react'
;
import TextArea from "../form_items/TextArea"
import {
  postRequest,
  deleteRequest,
  putRequest,
 } from "../../utils/api"

import {
  TrashCan32,
  Edit32,
} from '@carbon/icons-react'

import { gaEvent } from "../../utils/ga";

const defaultState = {
  editCommentText: { value: "", error: "" },
  editingComment: "",
  deletingComment: "",
  selectingComment: "",
}

const DealTransactionsComments = ({
  dealTransactionId = "",
  comments = [],
  currentUser = "",
  onCommentsChange,
  onTimestampClick,
  newComment,
  onCommentChange,
  commentRef,
  enableComments,
}) => {
  const [state, setState] = useState(defaultState)

  const handleComment = async () => {
    gaEvent("Commets", "Comment button click")

    try {
      const { response, data } = await postRequest(`/deal_transactions/${dealTransactionId}/comments`, {
        text: newComment.value,
      })

      if (response.ok) {
        const { comments } = data
        onCommentsChange(comments)
      } else {
        onCommentChange("_attribute", "", ["This field is required"])
      }
    } catch(error) {
      onCommentChange("_attribute", "", ["An error ocurred submitting the comment"])
    }
  }

  const handleDelete = async (commentId) => {
    gaEvent("Commets", "Delete button click", `Comment: ${commentId}`)

    try {
      const { response, data } = await deleteRequest(`/deal_transactions/${dealTransactionId}/comments/${commentId}`)

      if (response.ok) {
        const { comments } = data
        onCommentsChange(comments)
        setState({ ...state, deletingComment: "" })
      } else {
        console.error("An error ocurred deleting the comment")
      }
    } catch(error) {
      console.error("An error ocurred deleting the comment")
    }
  }

  const handleEdit = async (commentId) => {
    gaEvent("Commets", "Edit button click", `Comment: ${commentId}`)

    try {
      const { response, data } = await putRequest(`/deal_transactions/${dealTransactionId}/comments/${commentId}`, {
        text: state.editCommentText.value,
      })

      if (response.ok) {
        const { comments } = data
        onCommentsChange(comments)
        setState({ ...state, editingComment: "" })
      } else {
        setState({ ...state, editCommentText: { value: "", error: "This field is required"} })
      }
    } catch(error) {
      console.error("An error ocurred editing the comment")
    }
  }

  const handleEditCommentChange = (_attribute, value) => {
    setState({ ...state, editCommentText: { value: value, error: "" } })
  }

  const renderCommentText = (text) => {
    const pattern = /[0-9]:[0-5][0-9]:[0-5][0-9]|[0-5][0-9]:[0-5][0-9]:[0-5][0-9]|[0-9]:[0-5][0-9]|[0-5][0-9]:[0-5][0-9]/g

    const timestamps = text.match(pattern)
    const sentences = text.split(pattern)

    return (
      sentences.map((sentence, index) => (
        <React.Fragment key={index}>
          { sentence.split(" ").map((word, indexWord) => (
              word && <p key={indexWord}>{word}&nbsp;</p>
            ))
          }
          { timestamps && index < timestamps.length &&
            <p><a href="#" onClick={() => {onTimestampClick(timestamps[index])}}>{timestamps[index]}</a>&nbsp;</p>
          }
        </React.Fragment>
      ))
    )
  }

  return(
    <div className="fi--px--0">
      { enableComments &&
        <div>
          <TextArea
            ref={commentRef}
            className="fi--mt--24"
            name="comment"
            invalidText={newComment.errors.join(", ")}
            label={`Commenting as ${currentUser}. Use a colon to add a timestamp, e.g. "5:25".`}
            value={newComment.value}
            onChange={onCommentChange}
            rows={5}
          />
          <button className="bx--btn bx--btn--primary fi--btn--text-center fi--justify-content-left fi--mr--12 fi--mt--24" onClick={handleComment} type="button">Comment</button>
        </div>
      }
      
      <h3 className="fi--mt--48">{comments.length == 1 ? "1 Comment" : `${comments.length} Comments`}</h3>
      {
        comments.map(comment => (
          <div key={comment.id} className="fi--ml--16" onMouseOver={() => { setState({ ...state, selectingComment: comment.id }) }} onMouseOut={() => { setState({ ...state, selectingComment: "" }) }}>
            <Row className="fi--mt--32" style={{ height: "20px" }}>
              <Column>
                <Row>
                  <h5>{comment.user}</h5>
                  <h5 className="fi--ml--12" style={{ color: 'gray' }}>{comment.date}</h5>
                </Row>
              </Column>
              { comment.user == currentUser &&
                <Column>
                  <Row className={state.selectingComment == comment.id ? "fi--justify-content--flex-end fi--align-items--center" : "fi--hidden" }> 
                    <button onClick={() => { setState({ ...state, editingComment: comment.id, editCommentText: { value: comment.text, error: "" } }) }} ><Edit32 className="bx--btn__icon fi--option-comment-button"/></button>
                    <span className="fi--ml--24 fi--mr--16">
                      <Tooltip
                        open={state.deletingComment == comment.id}
                        showIcon={false}
                        triggerText={<button onClick={() => { setState({ ...state, deletingComment: comment.id }) }} ><TrashCan32 className="bx--btn__icon fi--option-comment-button"/></button>}
                      >
                        <p>Are you sure you want to permanently delete this comment?</p>
                        <div className="bx--tooltip__footer">
                          <button onClick={() => { setState({ ...state, deletingComment: "" }) }} className="bx--link">Cancel</button>
                          <button onClick={() => handleDelete(comment.id)} className="bx--btn bx--btn--primary bx--btn--sm"
                            type="button">Delete</button>
                        </div>
                      </Tooltip>
                    </span>
                  </Row>
                </Column>
              }
            </Row>
            <Row className="fi--mt--8">
              { state.editingComment == comment.id ?
                  <div className="fi--width--100 fi--pr--16">
                    <TextArea
                      name={`editCommentText-${comment.id}`}
                      invalidText={state.editCommentText.error}
                      label=""
                      value={state.editCommentText.value}
                      onChange={handleEditCommentChange}
                      rows={3}
                    />
                    <Row>
                      <button
                        className="bx--btn bx--btn--secondary bx--btn--sm fi--btn--text-center fi--justify-content-left fi--ml--16 fi--mr--12 fi--mt--12"
                        data-gaevent="Cancel edit buttom click"
                        data-gacategory="Comments"
                        onClick={() => setState({ ...state, editingComment: "" })}
                        type="button">
                          Cancel
                        </button>
                      <button
                        className="bx--btn bx--btn--primary bx--btn--sm fi--btn--text-center fi--justify-content-left fi--mt--12"
                        onClick={() => handleEdit(comment.id)}
                        type="button">
                          Save edit
                        </button>
                    </Row>
                  </div>
                :
                  renderCommentText(comment.text)
              }
            </Row>
          </div>
        ))
      }
    </div>
  )
}

DealTransactionsComments.propTypes = {
  dealTransactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comments: PropTypes.array,
  currentUser: PropTypes.string,
  onCommentsChange: PropTypes.func.isRequired,
  onTimestampClick: PropTypes.func,
  newComment: PropTypes.shape({
    value: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
  }),
  onCommentChange: PropTypes.func.isRequired,
  commentRef: PropTypes.object,
  enableComments: PropTypes.bool,
}

export default DealTransactionsComments
