import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from "prop-types"
import { Search } from 'carbon-components-react'

const WAIT_INTERVAL = 1000
const ENTER_KEY = 13

let timer = null

const SearchBar = forwardRef(({ onChange, initialValue = "", placeholder = "Search", size = "xl", delay = WAIT_INTERVAL }, ref) => {
  const [value, setValue] = useState(initialValue || "")

  const isFirstRun = useRef(true);

  useImperativeHandle(ref, () => ({
    clear() {
      setValue("")
    }
  }));

  const handleChange = e => {
    clearTimeout(timer)
    setValue(e.target.value)
  }

  const triggerChange = (enterPressed = false) => {
    onChange(value, enterPressed)
  }

  const handleKeyDown = e => {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault()
      clearTimeout(timer)
      triggerChange(true)
    }
  }

  const handleUpdate = () => {
    timer = setTimeout(triggerChange, delay)
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    handleUpdate()
  }, [value])

  return (
    <Search
      placeHolderText={placeholder}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={value}
      size={size}
      labelText="" />
  )
})

SearchBar.propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  delay: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

SearchBar.displayName = 'SearchBar';

export default SearchBar
