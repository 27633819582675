require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("carbon-components/src/globals/js/boot")

require("babel-polyfill")
require("whatwg-fetch")
require("url-search-params-polyfill");
require("element-closest-polyfill")

var humps = require("humps")
gon = humps.camelizeKeys(gon)

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
var $ = require("jquery");
ReactRailsUJS.useContext(componentRequireContext);

/*eslint-disable */
$(document).on('click', 'button, a', function () {
  tagName = event.target.tagName
  if (tagName == "SPAN" || tagName == "svg" || tagName == "path") {
    parent = event.target.closest("a")

    gaEvent = parent.dataset.gaevent
    gaCategory = parent.dataset.gacategory
    gaLabel = parent.dataset.galabel
  } else {
    gaEvent = event.target.dataset.gaevent
    gaCategory = event.target.dataset.gacategory
    gaLabel = event.target.dataset.galabel
  }

  gtag('event', gaEvent, {
    'event_category': gaCategory,
    'event_label': gaLabel,
  });
});
/*eslint-enable */
