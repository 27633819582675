const videoSaveDurationItems = [
  { id: "60_days", label: "60 days" },
  { id: "90_days", label: "90 days" },
  { id: "180_days", label: "180 days" },
  { id: "365_days", label: "365 days" },
  { id: "730_days", label: "2 years" },
  { id: "1095_days", label: "3 years" },
  { id: "1460_days", label: "4 years" },
  { id: "1825_days", label: "5 years" },
  { id: "2190_days", label: "6 years" },
  { id: "2555_days", label: "7 years" },
  { id: "indefinitely",  label: "Indefinitely" },
]

const typeItems = [
  { id: "dealer-group", label: "Dealer group" },
  { id: "region", label: "Region" },
  { id: "dealership", label: "Dealership" },
]

const consentItems = [
  { id: "2_party", label: "2-party" },
  { id: "1_party", label: "1-party" },
]

const recordingTimeoutItems = [
  { id: "30", label: "30 minutes" },
  { id: "45", label: "45 minutes" },
  { id: "60", label: "60 minutes" },
  { id: "90", label: "90 minutes" },
]

export {
  videoSaveDurationItems,
  typeItems,
  consentItems,
  recordingTimeoutItems,
}
